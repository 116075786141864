import React, { useState, useEffect, useContext, Fragment } from "react";
import { Container } from "shards-react";

import { API, graphqlOperation, Storage } from "aws-amplify";
import { listCategorys, listSubCategorys } from "../graphql/queries";
import { deleteCategory } from "../graphql/mutations";
import { GetCategoryContext } from "../context/GetCategoryContext";
import { GetUserByIdContext } from "../context/GetUserByIdContext";

import ViewCategory from "../components/items/ViewCategory";
import ViewSubCategory from "../components/items/ViewSubCategory";
import Loading from "../components/common/Loading";
import MenssageCarrusell from "../components/common/MenssageCarrusell";

const breakPoints = [
  { width: 1, itemsToShow: 1, outline: " none" },
  { width: 550, itemsToShow: 2, outline: " none" },
  { width: 768, itemsToShow: 3, outline: " none" },
  { width: 1200, itemsToShow: 4, outline: " none" },
];

const BooksCategory = () => {
  const [loading, setLoading] = useState(false);
  const [loadingsub, setLoadingSub] = useState(false);

  const { getuserbyid, refreshgetuserbyid } = useContext(GetUserByIdContext);

  //? Mandamos a llamar los estados globales de categoria y subcategoria
  const {
    getcategory,
    setGetCategory,
    getsubcategory,
    setGetSubCategory,
    viewsubcategory,
    setViewSubCategory,
    refreshgetcategory,
    setRefreshGetCategory,
  } = useContext(GetCategoryContext);

  //? Obtenemos todas las categorias existentes
  useEffect(() => {
    if (refreshgetcategory) {
      const listDataCategory = async () => {
        try {
          const fetchData = await API.graphql(graphqlOperation(listCategorys));

          const data = fetchData.data.listCategorys.items;

          setGetCategory(data);
          setRefreshGetCategory(!refreshgetcategory);
        } catch (err) {
          setRefreshGetCategory(!refreshgetcategory);
          console.log(err);
        }
      };

      listDataCategory();
    }
  }, [setGetCategory, refreshgetcategory, setRefreshGetCategory]);

  //? Obtenemos todas las subcategorias de la categoria seleccionada
  const getSubCategories = async (idCategory) => {
    setLoadingSub(true);
    try {
      const fetchData = await API.graphql(
        graphqlOperation(listSubCategorys, {
          filter: {
            categoryNameItemId: {
              eq: `${idCategory}`,
            },
          },
        })
      );

      const data = fetchData.data.listSubCategorys.items;

      setGetSubCategory(data);
      setLoadingSub(false);
      setViewSubCategory(true);
    } catch (err) {
      setLoadingSub(false);
      console.log(err);
    }
  };

  //? Eliminamos la categoria en dynamoDB
  const deleteCategoryToDB = async (categoryId) => {
    setLoading(true);

    const imageCategory = getcategory.find(
      (category) => category.id === categoryId.id
    );

    const nameImageCategory = imageCategory.imageNameCategoryItem;

    try {
      await API.graphql(
        graphqlOperation(deleteCategory, {
          input: categoryId,
        })
      );

      deleteCategoryToS3(nameImageCategory);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //? Eliminamos la imagen de la categoria en s3
  const deleteCategoryToS3 = async (nameImageCategory) => {
    try {
      await Storage.remove("images/" + nameImageCategory);

      setRefreshGetCategory(!refreshgetcategory);
      setLoading(false);
      console.log("delete completed success");
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  if (loading) return <Loading />;
  if (refreshgetcategory) return <Loading />;
  if (refreshgetuserbyid) return <Loading />;

  return (
    <Container fluid className="main-content-container px-4">
      <ViewCategory
        getuserbyid={getuserbyid}
        breakPoints={breakPoints}
        getcategory={getcategory}
        getSubCategories={getSubCategories}
        deleteCategoryToDB={deleteCategoryToDB}
      />

      {viewsubcategory ? (
        <Fragment>
          {loadingsub ? (
            <Loading />
          ) : getsubcategory.length === 0 ? (
            <MenssageCarrusell />
          ) : (
            <ViewSubCategory
              getuserbyid={getuserbyid}
              breakPoints={breakPoints}
              getsubcategory={getsubcategory}
            />
          )}
        </Fragment>
      ) : (
        ""
      )}
    </Container>
  );
};

export default BooksCategory;
