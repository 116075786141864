import React from "react";
import { Form, Col, FormInput, FormGroup, Button } from "shards-react";

const ChangePassword = ({
  userdata,
  handleChange,
  handleSubmit,
  handleClick,
}) => {
  return (
    <div className="popup ">
      <div className="popup_inner form-group">
        <div className="body-scroll2 heigt-Perfil-user-password-form">
          <Col>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <label className="margin-bordeder" htmlFor="username">
                  Usuario
                </label>
                <FormInput
                  id="username"
                  placeholder="usuario"
                  name="username"
                  value={userdata.username}
                  disabled="disabled"
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="oldPassword">Contraseña anterior</label>
                <FormInput
                  type="password"
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="Contraseña anterior"
                  onChange={handleChange}
                />
              </FormGroup>

              <FormGroup>
                <label htmlFor="newPassword">Nueva Contraseña</label>
                <FormInput
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="Nueva Contraseña"
                  onChange={handleChange}
                />
              </FormGroup>

              <div className="btn-card-perfil">
                <Button pill className="btn-contraseña" type="submit">
                  Guardar
                </Button>
                <Button
                  pill
                  theme="danger"
                  className="btn-contraseña ml-2"
                  onClick={handleClick}
                >
                  Cancelar
                </Button>
              </div>
            </Form>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
