import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { listItems } from "../graphql/queries";

import { GetItemsContext } from "../context/GetItemsContext";
import { GetItemByIdContext } from "../context/GetItemByIdContext";
import { GetUserByIdContext } from "../context/GetUserByIdContext";
import Menssage from "../components/common/Menssage";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import ListItems from "../components/items/ListItems";
import Loading from "../components/common/Loading";
import Alert from "../components/common/Alert";

const BooksApproval = () => {
  const [error, setError] = useState(false);
  const { setMatchItem } = useContext(GetItemByIdContext);
  const { getuserbyid, refreshgetuserbyid } = useContext(GetUserByIdContext);

  const {
    getprivateitems,
    setGetPrivateItems,
    refreshgetprivateitems,
    setRefreshGetPrivateItems,
    contitems,
    setContItems,
    contgetprivateitems,
    setContGetPrivateItems,
    contgetpublicitems,
    setContGetPubliItems,
  } = useContext(GetItemsContext);

  useEffect(() => {
    if (refreshgetprivateitems) {
      const getResource = async () => {
        try {
          const { data } = await API.graphql(graphqlOperation(listItems));
          const dataItems = data.listItems.items;

          const privateItem = dataItems.filter(
            (item) => item.accessItem === "private"
          );
          const publicItem = dataItems.filter(
            (item) => item.accessItem === "public"
          );

          setGetPrivateItems(privateItem);
          setContItems(dataItems.length);
          setContGetPrivateItems(privateItem.length);
          setContGetPubliItems(publicItem.length);
          setRefreshGetPrivateItems(false);
        } catch (err) {
          setRefreshGetPrivateItems(false);
          setError(true);
        }
      };

      getResource();
    }
    setMatchItem(null);
  }, [
    setContItems,
    setContGetPubliItems,
    getuserbyid.userType,
    refreshgetprivateitems,
    setGetPrivateItems,
    setRefreshGetPrivateItems,
    setContGetPrivateItems,
    setMatchItem,
    setError,
  ]);

  if (refreshgetprivateitems) return <Loading />;
  if (refreshgetuserbyid) return <Loading />;

  if (getuserbyid.userType === "administradores") {
    return (
      <Fragment>
        {error ? (
          <Alert title="An unexpected error has occurred" body="" />
        ) : (
          ""
        )}

        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle
              sm="10"
              title="Aprobación de documentos"
              className="text-sm-left"
            />
            {getuserbyid.userType === "administradores" ? (
              <Col className="bottom-center">
                <Link to="/books-approval/add/book">
                  <Button pill sm="2" md="6" xs="2">
                    Subir documentos
                  </Button>
                </Link>
              </Col>
            ) : (
              ""
            )}
          </Row>

          <Row>
            <Col lg="3" md="6" sm="12" className="mb-4">
              <Card small className="card text-center mb-3">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Documentos subidos</h6>
                </CardHeader>
                <CardBody>
                  <h1>{contitems}</h1>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="12" className="mb-4">
              <Card small className="card text-center mb-3">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Documentos aprobados</h6>
                </CardHeader>
                <CardBody>
                  <h1>{contgetpublicitems}</h1>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3" md="6" sm="12" className="mb-4">
              <Card small className="card text-center mb-3">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Documentos pendientes</h6>
                </CardHeader>
                <CardBody>
                  <h1>{contgetprivateitems}</h1>
                </CardBody>
              </Card>
            </Col>

            <ListItems dataItem={getprivateitems} />
          </Row>
        </Container>
      </Fragment>
    );
  } else {
    return <Menssage />;
  }
};

export default BooksApproval;
