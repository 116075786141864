import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";
import { GetUserByIdContext } from "../../../../context/GetUserByIdContext";

const UserActions = () => {
  const [visible, setVisible] = useState(false);
  const { getuserbyid } = useContext(GetUserByIdContext);

  const toggleUserActions = () => setVisible(!visible);

  const handleClick = async () => {
    try {
      await Auth.signOut();
      window.location.href = "/signin";
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
      <DropdownToggle
        caret
        tag={NavLink}
        className="text-nowrap px-3"
        style={{ cursor: "pointer" }}
      >
        <img
          className="user-avatar rounded-circle mr-2"
          src={require("./../../../../images/avatars/4.jpeg")}
          alt="User Avatar"
        />{" "}
        <span className="d-none d-md-inline-block">
          {getuserbyid.firstName + " " + getuserbyid.lastName}
        </span>
      </DropdownToggle>
      <Collapse
        tag={DropdownMenu}
        right
        small
        open={visible}
        style={{ position: "initial" }}
      >
        <DropdownItem tag={Dropdown}>
          <Link to="/user-profile" style={{ textDecoration: "none" }}>
            <i className="material-icons">&#xE8B8;</i> Editar Perfil
          </Link>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Dropdown} className="text-danger">
          <span onClick={handleClick} style={{ cursor: "pointer" }}>
            <i className="material-icons text-danger">&#xE879;</i> Salir
          </span>
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
};

export default UserActions;
