import React from "react";
import "../../styles/Loading.css";
import { Container } from "shards-react";

const Loading = () => (
  <Container fluid className="main-content-container px-4">
    <div className="loader">Loading...</div>
  </Container>
);

export default Loading;
