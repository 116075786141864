import React, { Fragment } from "react";
import Amplify from "aws-amplify";
import aws_exports from "./aws-exports";
import { GetItemsProvider } from "./context/GetItemsContext";
import { GetUsersProvider } from "./context/GetUsersContext";
import { GetItemByIdProvider } from "./context/GetItemByIdContext";
import { GetUserByIdProvider } from "./context/GetUserByIdContext";
import { GetCategoryProvider } from "./context/GetCategoryContext";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import PasswordReset from "./components/resetpass/PasswordReset";

import routes from "./routes";
import withTracker from "./withTracker";

Amplify.configure(aws_exports);

const App = () => {
  return (
    <GetItemsProvider>
      <GetUsersProvider>
        <GetItemByIdProvider>
          <GetUserByIdProvider>
            <GetCategoryProvider>
              <Fragment>
                <Router>
                  <Switch>
                    <Route
                      exact
                      path="/"
                      component={() => <Redirect to="/signin" />}
                    />
                    <Route path="/signin" component={SignIn} />
                    <Route path="/signup" component={SignUp} />
                    <Route path="/password-reset" component={PasswordReset} />
                    <Fragment>
                      {routes.map((route, id) => (
                        <Route
                          key={id}
                          path={route.path}
                          exact={route.exact}
                          component={withTracker((props) => (
                            <route.layout {...props}>
                              <route.component {...props} />
                            </route.layout>
                          ))}
                        />
                      ))}
                    </Fragment>
                  </Switch>
                </Router>
              </Fragment>
            </GetCategoryProvider>
          </GetUserByIdProvider>
        </GetItemByIdProvider>
      </GetUsersProvider>
    </GetItemsProvider>
  );
};

export default App;
