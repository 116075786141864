import React, { useState, Fragment } from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import UserNotFound from "./UserNotFound";
import UserFound from "./UserFound";
import { Modal, ModalHeader, ModalBody } from "shards-react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(0, 0, 5),
    borderRadius: "30px",
    height: "65%",
  },
}));

const ChangePassword = ({ history }) => {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [changepass, setChangepass] = useState({
    username: "",
    password: "",
    confirmationCode: "",
    foundUser: false,
  });

  const ChangePass = () => {
    const { username } = changepass;
    Auth.forgotPassword(username)
      .then(() => {
        setChangepass({
          username: username,
          password: "",
          foundUser: true,
        });
      })
      .catch(() => setError(true));
  };

  const ConfirmChange = () => {
    const { username, confirmationCode, password } = changepass;

    Auth.forgotPasswordSubmit(username, confirmationCode, password)
      .then(() => {
        setChangepass({ username: "", confirmationCode: "" });
        alert("Su contraseña ha sido cambiada correctamente");
      })
      .catch(() => setError(true));
  };

  const handleSubmit = (e) => {
    const { foundUser } = changepass;
    e.preventDefault();

    foundUser ? ConfirmChange() : ChangePass();
  };

  const handleChange = (e) => {
    setChangepass({
      ...changepass,
      [e.target.name]: e.target.value,
    });
  };

  if (!changepass.foundUser)
    return (
      <Fragment>
        {error ? (
          <Fragment>
            <Modal open={error} toggle={() => setError(!error)}>
              <ModalHeader>Usuario no encontrado</ModalHeader>
              <ModalBody>Por favor ingrese de nuevo sus datos</ModalBody>
            </Modal>
          </Fragment>
        ) : (
          ""
        )}

        <UserNotFound
          classes={classes}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
        />
      </Fragment>
    );

  return (
    <Fragment>
      {error ? (
        <Fragment>
          <Modal open={error} toggle={() => setError(!error)}>
            <ModalHeader>Código incorrecto</ModalHeader>
            <ModalBody>
              Por favor ingrese de nuevo sus datos y segurese que la contraseña
              tenga mas de 8 caracteres
            </ModalBody>
          </Modal>
        </Fragment>
      ) : (
        ""
      )}

      <UserFound
        classes={classes}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        changepass={changepass}
      />
    </Fragment>
  );
};

export default ChangePassword;
