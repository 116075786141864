import React, { Fragment, useState, useContext } from "react";
import { GetUsersContext } from "../../context/GetUsersContext";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { createUser } from "../../graphql/mutations";
import AddUserForm from "./AddUserForm";
import Loading from "../common/Loading";
import Alert from "../common/Alert";

const AddUser = ({ history }) => {
  const [useradmin, setUserAdmin] = useState({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone_number: "",
    address: "",
    description: "",
    confirmationCode: "",
    verified: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({ message: "" });
  const { setRefreshGetUsers } = useContext(GetUsersContext);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    const { verified } = useradmin;
    setLoading(true);

    !verified ? createUserToCongito() : confirmUserToCongnito();
  };

  const createUserToCongito = async () => {
    const { username, email, password, phone_number } = useradmin;

    try {
      await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          email: email,
          phone_number: "+502" + phone_number,
        },
      });

      setError(false);
      setInfo({ message: "" });
      setUserAdmin({ ...useradmin, verified: true });
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      setInfo(err);
    }
  };

  const confirmUserToCongnito = async () => {
    const {
      username,
      firstName,
      lastName,
      email,
      phone_number,
      address,
      description,
      confirmationCode,
    } = useradmin;

    const dataUserObject = {
      id: username,
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phone_number,
      address: address,
      description: description,
      userType: "administradores",
      baseTypeUser: "usuarios",
    };

    try {
      await Auth.confirmSignUp(username, confirmationCode);

      setError(false);
      setInfo({ message: "" });
      createUserToDB(dataUserObject);
    } catch (err) {
      setError(true);
      setLoading(false);
      setInfo(err);
    }
  };

  const createUserToDB = async (userData) => {
    try {
      await API.graphql(graphqlOperation(createUser, { input: userData }));
      setLoading(false);
      setRefreshGetUsers(true);
      history.push("/users");
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  };

  const handleChange = (evt) => {
    setUserAdmin({
      ...useradmin,
      [evt.target.name]: evt.target.value,
    });
  };

  if (loading) return <Loading />;

  return (
    <Fragment>
      {error ? (
        <Alert title="An unexpected error has occurred" body={info.message} />
      ) : (
        ""
      )}
      <AddUserForm
        userData={useradmin}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </Fragment>
  );
};

export default AddUser;
