import React, { useState, createContext } from "react";

export const GetItemsContext = createContext();

export const GetItemsProvider = (props) => {
  const [getitems, setGetItems] = useState([]);
  const [getprivateitems, setGetPrivateItems] = useState([]);
  const [refreshgetitems, setRefreshGetItems] = useState(true);
  const [refreshgetprivateitems, setRefreshGetPrivateItems] = useState(true);
  const [contitems, setContItems] = useState(0);
  const [contgetpublicitems, setContGetPubliItems] = useState(0);
  const [contgetprivateitems, setContGetPrivateItems] = useState(0);
  const [inputsearch, setInputSearch] = useState("");
  const [search, setSearch] = useState([]);

  return (
    <GetItemsContext.Provider
      value={{
        getitems,
        setGetItems,
        getprivateitems,
        setGetPrivateItems,
        refreshgetitems,
        setRefreshGetItems,
        refreshgetprivateitems,
        setRefreshGetPrivateItems,
        contitems,
        setContItems,
        contgetprivateitems,
        setContGetPrivateItems,
        contgetpublicitems,
        setContGetPubliItems,
        search,
        setSearch,
        inputsearch,
        setInputSearch,
      }}
    >
      {props.children}
    </GetItemsContext.Provider>
  );
};
