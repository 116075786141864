import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Nav, NavItem, NavLink } from "shards-react";
import { Link } from "react-router-dom";

const MainFooter = ({ contained, menuItems, copyright }) => (
  <footer className="main-footer d-flex p-2 px-3 bg-white border-top">
    <Container fluid={contained}>
      <Row>
        <Nav>
          <NavItem>
            <NavLink tag={Link} to="/books-overview">
              Inicio
            </NavLink>
          </NavItem>
          {menuItems.map((item, id) => (
            <NavItem key={id}>
              <NavLink href={item.to} target="_blank">
                {item.title}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <a
          className="copyright ml-auto my-auto mr-2"
          href="http://www.colab.com.gt"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
        >
          {copyright}
        </a>
      </Row>
    </Container>
  </footer>
);

MainFooter.propTypes = {
  contained: PropTypes.bool, //?Whether the content is contained, or not.
  menuItems: PropTypes.array, //?The menu items array.
  copyright: PropTypes.string, //?The copyright info.
};

MainFooter.defaultProps = {
  contained: false,
  copyright: "Copyright © 2020 Colab",
  menuItems: [
    {
      title: "CentraRSE",
      to: "https://centrarse.org/",
    },
    {
      title: "Giz",
      to: "https://www.giz.de/de/html/index.html",
    },
    {
      title: "Acerca de",
      to: "https://centrarse.org/Seccion/56/Sobre-CentraRSE.html",
    },
  ],
};

export default MainFooter;
