import React from "react";

import { Card, CardHeader, ListGroup, ListGroupItem } from "shards-react";

const UserDetails = ({ userdata, setUserData, change, setChange }) => {
  return (
    <Card small className="mb-4 pt-3">
      <div className="button-list-config">
        <span
          onClick={() => {
            setUserData(userdata);
            setChange({ show: !change.show });
          }}
          className="material-icons pointer-img"
        >
          more_horiz
        </span>
        {change.show ? (
          <div className="list-config-user scale-up-ver-top ">
            <ListGroup>
              <ListGroupItem
                className="pointer-img"
                onClick={() => {
                  setChange({ user: !change.user });
                }}
              >
                Editar Perfil
              </ListGroupItem>
              <ListGroupItem
                className="pointer-img"
                onClick={() => {
                  setChange({ password: !change.password });
                }}
              >
                Cambiar Contraseña
              </ListGroupItem>
            </ListGroup>
          </div>
        ) : null}
      </div>

      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          <img
            className="rounded-circle"
            src={require("../../images/avatars/4.jpeg")}
            alt={userdata.username}
            width="110"
          />
        </div>
        <h4 className="mb-0">{userdata.firstName + " " + userdata.lastName}</h4>
        <span className="text-muted d-block mb-2">{userdata.email}</span>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-4">
          <strong className="text-muted d-block mb-2">Descripción</strong>
          <span>{userdata.description}</span>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

export default UserDetails;
