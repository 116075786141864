/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      phoneNumber
      birthday
      gender
      country
      company
      email
      address
      description
      baseTypeUser
      userType
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      phoneNumber
      birthday
      gender
      country
      company
      email
      address
      description
      baseTypeUser
      userType
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      firstName
      lastName
      phoneNumber
      birthday
      gender
      country
      company
      email
      address
      description
      baseTypeUser
      userType
      createdAt
      updatedAt
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      titleItem
      authorItem
      descriptionItem
      fileItem {
        bucket
        region
        key
      }
      fileNameItem
      baseTypeItem
      accessItem
      categoryItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoryItem {
        id
        titleSubCategoryItem
        colorSubCategoryThemeItem
        descriptionSubCategoryItem
        imageSubCategoryItem {
          bucket
          region
          key
        }
        imageNameSubCategoryItem
        baseTypeSubCategoryItem
        categoryNameItemId
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        itemsSubCategories {
          nextToken
        }
        subCategoryPrograms {
          nextToken
        }
        createdAt
        updatedAt
      }
      programItem {
        id
        titleProgramItem
        colorTagProgramItem
        descriptionProgramItem
        imageProgramItem {
          bucket
          region
          key
        }
        imageNameProgramItem
        baseTypeProgramItem
        categoryNameItemId
        subCategoryNameItemId
        categoryProgramItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        subCategoryProgramItem {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        programItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      titleItem
      authorItem
      descriptionItem
      fileItem {
        bucket
        region
        key
      }
      fileNameItem
      baseTypeItem
      accessItem
      categoryItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoryItem {
        id
        titleSubCategoryItem
        colorSubCategoryThemeItem
        descriptionSubCategoryItem
        imageSubCategoryItem {
          bucket
          region
          key
        }
        imageNameSubCategoryItem
        baseTypeSubCategoryItem
        categoryNameItemId
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        itemsSubCategories {
          nextToken
        }
        subCategoryPrograms {
          nextToken
        }
        createdAt
        updatedAt
      }
      programItem {
        id
        titleProgramItem
        colorTagProgramItem
        descriptionProgramItem
        imageProgramItem {
          bucket
          region
          key
        }
        imageNameProgramItem
        baseTypeProgramItem
        categoryNameItemId
        subCategoryNameItemId
        categoryProgramItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        subCategoryProgramItem {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        programItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      titleItem
      authorItem
      descriptionItem
      fileItem {
        bucket
        region
        key
      }
      fileNameItem
      baseTypeItem
      accessItem
      categoryItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoryItem {
        id
        titleSubCategoryItem
        colorSubCategoryThemeItem
        descriptionSubCategoryItem
        imageSubCategoryItem {
          bucket
          region
          key
        }
        imageNameSubCategoryItem
        baseTypeSubCategoryItem
        categoryNameItemId
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        itemsSubCategories {
          nextToken
        }
        subCategoryPrograms {
          nextToken
        }
        createdAt
        updatedAt
      }
      programItem {
        id
        titleProgramItem
        colorTagProgramItem
        descriptionProgramItem
        imageProgramItem {
          bucket
          region
          key
        }
        imageNameProgramItem
        baseTypeProgramItem
        categoryNameItemId
        subCategoryNameItemId
        categoryProgramItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        subCategoryProgramItem {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        programItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      titleCategoryItem
      colorTagCategoryItem
      descriptionCategoryItem
      imageCategoryItem {
        bucket
        region
        key
      }
      imageNameCategoryItem
      baseTypeCategoryItem
      itemsCategories {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      subCategories {
        items {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      programs {
        items {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      titleCategoryItem
      colorTagCategoryItem
      descriptionCategoryItem
      imageCategoryItem {
        bucket
        region
        key
      }
      imageNameCategoryItem
      baseTypeCategoryItem
      itemsCategories {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      subCategories {
        items {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      programs {
        items {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      titleCategoryItem
      colorTagCategoryItem
      descriptionCategoryItem
      imageCategoryItem {
        bucket
        region
        key
      }
      imageNameCategoryItem
      baseTypeCategoryItem
      itemsCategories {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      subCategories {
        items {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      programs {
        items {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSubCategory = /* GraphQL */ `
  mutation CreateSubCategory(
    $input: CreateSubCategoryInput!
    $condition: ModelSubCategoryConditionInput
  ) {
    createSubCategory(input: $input, condition: $condition) {
      id
      titleSubCategoryItem
      colorSubCategoryThemeItem
      descriptionSubCategoryItem
      imageSubCategoryItem {
        bucket
        region
        key
      }
      imageNameSubCategoryItem
      baseTypeSubCategoryItem
      categoryNameItemId
      categoryItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      itemsSubCategories {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      subCategoryPrograms {
        items {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSubCategory = /* GraphQL */ `
  mutation UpdateSubCategory(
    $input: UpdateSubCategoryInput!
    $condition: ModelSubCategoryConditionInput
  ) {
    updateSubCategory(input: $input, condition: $condition) {
      id
      titleSubCategoryItem
      colorSubCategoryThemeItem
      descriptionSubCategoryItem
      imageSubCategoryItem {
        bucket
        region
        key
      }
      imageNameSubCategoryItem
      baseTypeSubCategoryItem
      categoryNameItemId
      categoryItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      itemsSubCategories {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      subCategoryPrograms {
        items {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSubCategory = /* GraphQL */ `
  mutation DeleteSubCategory(
    $input: DeleteSubCategoryInput!
    $condition: ModelSubCategoryConditionInput
  ) {
    deleteSubCategory(input: $input, condition: $condition) {
      id
      titleSubCategoryItem
      colorSubCategoryThemeItem
      descriptionSubCategoryItem
      imageSubCategoryItem {
        bucket
        region
        key
      }
      imageNameSubCategoryItem
      baseTypeSubCategoryItem
      categoryNameItemId
      categoryItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      itemsSubCategories {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      subCategoryPrograms {
        items {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProgram = /* GraphQL */ `
  mutation CreateProgram(
    $input: CreateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    createProgram(input: $input, condition: $condition) {
      id
      titleProgramItem
      colorTagProgramItem
      descriptionProgramItem
      imageProgramItem {
        bucket
        region
        key
      }
      imageNameProgramItem
      baseTypeProgramItem
      categoryNameItemId
      subCategoryNameItemId
      categoryProgramItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoryProgramItem {
        id
        titleSubCategoryItem
        colorSubCategoryThemeItem
        descriptionSubCategoryItem
        imageSubCategoryItem {
          bucket
          region
          key
        }
        imageNameSubCategoryItem
        baseTypeSubCategoryItem
        categoryNameItemId
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        itemsSubCategories {
          nextToken
        }
        subCategoryPrograms {
          nextToken
        }
        createdAt
        updatedAt
      }
      programItems {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProgram = /* GraphQL */ `
  mutation UpdateProgram(
    $input: UpdateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    updateProgram(input: $input, condition: $condition) {
      id
      titleProgramItem
      colorTagProgramItem
      descriptionProgramItem
      imageProgramItem {
        bucket
        region
        key
      }
      imageNameProgramItem
      baseTypeProgramItem
      categoryNameItemId
      subCategoryNameItemId
      categoryProgramItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoryProgramItem {
        id
        titleSubCategoryItem
        colorSubCategoryThemeItem
        descriptionSubCategoryItem
        imageSubCategoryItem {
          bucket
          region
          key
        }
        imageNameSubCategoryItem
        baseTypeSubCategoryItem
        categoryNameItemId
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        itemsSubCategories {
          nextToken
        }
        subCategoryPrograms {
          nextToken
        }
        createdAt
        updatedAt
      }
      programItems {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProgram = /* GraphQL */ `
  mutation DeleteProgram(
    $input: DeleteProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    deleteProgram(input: $input, condition: $condition) {
      id
      titleProgramItem
      colorTagProgramItem
      descriptionProgramItem
      imageProgramItem {
        bucket
        region
        key
      }
      imageNameProgramItem
      baseTypeProgramItem
      categoryNameItemId
      subCategoryNameItemId
      categoryProgramItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoryProgramItem {
        id
        titleSubCategoryItem
        colorSubCategoryThemeItem
        descriptionSubCategoryItem
        imageSubCategoryItem {
          bucket
          region
          key
        }
        imageNameSubCategoryItem
        baseTypeSubCategoryItem
        categoryNameItemId
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        itemsSubCategories {
          nextToken
        }
        subCategoryPrograms {
          nextToken
        }
        createdAt
        updatedAt
      }
      programItems {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
