/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      firstName
      lastName
      phoneNumber
      birthday
      gender
      country
      company
      email
      address
      description
      baseTypeUser
      userType
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        firstName
        lastName
        phoneNumber
        birthday
        gender
        country
        company
        email
        address
        description
        baseTypeUser
        userType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      titleItem
      authorItem
      descriptionItem
      fileItem {
        bucket
        region
        key
      }
      fileNameItem
      baseTypeItem
      accessItem
      categoryItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoryItem {
        id
        titleSubCategoryItem
        colorSubCategoryThemeItem
        descriptionSubCategoryItem
        imageSubCategoryItem {
          bucket
          region
          key
        }
        imageNameSubCategoryItem
        baseTypeSubCategoryItem
        categoryNameItemId
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        itemsSubCategories {
          nextToken
        }
        subCategoryPrograms {
          nextToken
        }
        createdAt
        updatedAt
      }
      programItem {
        id
        titleProgramItem
        colorTagProgramItem
        descriptionProgramItem
        imageProgramItem {
          bucket
          region
          key
        }
        imageNameProgramItem
        baseTypeProgramItem
        categoryNameItemId
        subCategoryNameItemId
        categoryProgramItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        subCategoryProgramItem {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        programItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titleItem
        authorItem
        descriptionItem
        fileItem {
          bucket
          region
          key
        }
        fileNameItem
        baseTypeItem
        accessItem
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        subCategoryItem {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        programItem {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      titleCategoryItem
      colorTagCategoryItem
      descriptionCategoryItem
      imageCategoryItem {
        bucket
        region
        key
      }
      imageNameCategoryItem
      baseTypeCategoryItem
      itemsCategories {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      subCategories {
        items {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      programs {
        items {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubCategory = /* GraphQL */ `
  query GetSubCategory($id: ID!) {
    getSubCategory(id: $id) {
      id
      titleSubCategoryItem
      colorSubCategoryThemeItem
      descriptionSubCategoryItem
      imageSubCategoryItem {
        bucket
        region
        key
      }
      imageNameSubCategoryItem
      baseTypeSubCategoryItem
      categoryNameItemId
      categoryItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      itemsSubCategories {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      subCategoryPrograms {
        items {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSubCategorys = /* GraphQL */ `
  query ListSubCategorys(
    $filter: ModelSubCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titleSubCategoryItem
        colorSubCategoryThemeItem
        descriptionSubCategoryItem
        imageSubCategoryItem {
          bucket
          region
          key
        }
        imageNameSubCategoryItem
        baseTypeSubCategoryItem
        categoryNameItemId
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        itemsSubCategories {
          nextToken
        }
        subCategoryPrograms {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProgram = /* GraphQL */ `
  query GetProgram($id: ID!) {
    getProgram(id: $id) {
      id
      titleProgramItem
      colorTagProgramItem
      descriptionProgramItem
      imageProgramItem {
        bucket
        region
        key
      }
      imageNameProgramItem
      baseTypeProgramItem
      categoryNameItemId
      subCategoryNameItemId
      categoryProgramItem {
        id
        titleCategoryItem
        colorTagCategoryItem
        descriptionCategoryItem
        imageCategoryItem {
          bucket
          region
          key
        }
        imageNameCategoryItem
        baseTypeCategoryItem
        itemsCategories {
          nextToken
        }
        subCategories {
          nextToken
        }
        programs {
          nextToken
        }
        createdAt
        updatedAt
      }
      subCategoryProgramItem {
        id
        titleSubCategoryItem
        colorSubCategoryThemeItem
        descriptionSubCategoryItem
        imageSubCategoryItem {
          bucket
          region
          key
        }
        imageNameSubCategoryItem
        baseTypeSubCategoryItem
        categoryNameItemId
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        itemsSubCategories {
          nextToken
        }
        subCategoryPrograms {
          nextToken
        }
        createdAt
        updatedAt
      }
      programItems {
        items {
          id
          titleItem
          authorItem
          descriptionItem
          fileNameItem
          baseTypeItem
          accessItem
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPrograms = /* GraphQL */ `
  query ListPrograms(
    $filter: ModelProgramFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrograms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        titleProgramItem
        colorTagProgramItem
        descriptionProgramItem
        imageProgramItem {
          bucket
          region
          key
        }
        imageNameProgramItem
        baseTypeProgramItem
        categoryNameItemId
        subCategoryNameItemId
        categoryProgramItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        subCategoryProgramItem {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        programItems {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchItems = /* GraphQL */ `
  query SearchItems(
    $filter: SearchableItemFilterInput
    $sort: SearchableItemSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchItems(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        titleItem
        authorItem
        descriptionItem
        fileItem {
          bucket
          region
          key
        }
        fileNameItem
        baseTypeItem
        accessItem
        categoryItem {
          id
          titleCategoryItem
          colorTagCategoryItem
          descriptionCategoryItem
          imageNameCategoryItem
          baseTypeCategoryItem
          createdAt
          updatedAt
        }
        subCategoryItem {
          id
          titleSubCategoryItem
          colorSubCategoryThemeItem
          descriptionSubCategoryItem
          imageNameSubCategoryItem
          baseTypeSubCategoryItem
          categoryNameItemId
          createdAt
          updatedAt
        }
        programItem {
          id
          titleProgramItem
          colorTagProgramItem
          descriptionProgramItem
          imageNameProgramItem
          baseTypeProgramItem
          categoryNameItemId
          subCategoryNameItemId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
