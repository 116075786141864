import React, { useState, Fragment, useContext } from "react";
import { GetUserByIdContext } from "../context/GetUserByIdContext";
import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "../graphql/queries";
import { Auth } from "aws-amplify";

import SignInForm from "./SignInForm";
import Loading from "./common/Loading";
import Alert from "./common/Alert";

const SignIn = ({ history }) => {
  const initialState = {
    username: "",
    password: "",
    showpass: false,
    signedIn: false,
  };

  const [form, setForm] = useState(initialState);
  const [info, setInfo] = useState({ message: "" });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setGetUserById, setRefreshGetUserById } = useContext(
    GetUserByIdContext
  );

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);
    verifyUserToCognito();
  };

  const verifyUserToCognito = async () => {
    const { username, password } = form;

    try {
      await Auth.signIn({ username: username, password: password });

      fetchUserId(username);
    } catch (err) {
      setLoading(false);
      setError(true);
      setInfo(err);
    }
  };

  const fetchUserId = async (userId) => {
    try {
      const getUserById = await API.graphql(
        graphqlOperation(getUser, { id: userId })
      );

      const userDetails = getUserById.data.getUser;
      setGetUserById(userDetails);
      setRefreshGetUserById(false);
      history.push("/books-overview");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      setInfo(err);
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setForm({
      ...form,
      showpass: !form.showpass,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  if (loading) return <Loading />;

  return (
    <Fragment>
      {error ? (
        <Alert title="An unexpected error has occurred" body={info.message} />
      ) : (
        ""
      )}
      <SignInForm
        form={form}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      />
    </Fragment>
  );
};

export default SignIn;
