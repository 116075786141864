import React, { useState, createContext } from "react";

export const GetItemByIdContext = createContext();

export const GetItemByIdProvider = (props) => {
  const [itemid, setItemid] = useState({});
  const [matchitem, setMatchItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState({});

  return (
    <GetItemByIdContext.Provider
      value={{
        itemid,
        setItemid,
        matchitem,
        setMatchItem,
        loading,
        setLoading,
        file,
        setFile,
      }}
    >
      {props.children}
    </GetItemByIdContext.Provider>
  );
};
