import React, { useState, useEffect, useContext, Fragment } from "react";
import { Storage, API, graphqlOperation } from "aws-amplify";
import { createItem, updateItem } from "../../graphql/mutations";
import {
  listCategorys,
  listSubCategorys,
  listPrograms,
} from "../../graphql/queries";

import { GetItemByIdContext } from "../../context/GetItemByIdContext";
import { GetItemsContext } from "../../context/GetItemsContext";
import { GetUserByIdContext } from "../../context/GetUserByIdContext";

import awsExports from "../../aws-exports";
import AddItemForm from "./AddItemForm";
import Loading from "../common/Loading";
import Alert from "../common/Alert";

const AddItem = ({ match, history }) => {
  //? Inicializamos el estado inical del formulario
  const initialState = {
    titleItem: "Conduct at an replied removal an amongst",
    authorItem: "Anna Kunis",
    colorTagCategoryItem: "",
    categoryItemTitle: "",
    fileItem: null,
    fileNameItem: "",
    categoryItem: "",
    subCategoryItem: "",
    programItem: "",
    descriptionItem:
      "However venture pursuit he am mr cordial. Forming musical am hearing studied be luckily. But in for determine what would see",
  };

  const [item, setItem] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedAction, setSelectAction] = useState("document");

  //? Estado para las categorias
  const [loadigetcategory, setLoadingGetCategory] = useState(true);
  const [refreshcategory, setRefreshCategory] = useState(true);
  const [listcategories, setListCategories] = useState([
    { titleCategoryItem: "" },
  ]);

  //? Estado para las subcategorias
  const [loadigetsubcategory, setLoadingGetSubCategory] = useState(true);
  const [refreshsubcategory, setRefreshSubCategory] = useState(true);
  const [getsubcategories, setGetSubCategories] = useState(null);
  const [listsubcategories, setListSubCategories] = useState([
    { titleSubCategoryItem: "" },
  ]);

  //? Estado para los programas
  const [loadingetprogram, setLoadignGetProgram] = useState(true);
  const [refreshprograms, setRefreshPrograms] = useState(true);
  const [getprograms, setGetPrograms] = useState(null);
  const [listprograms, setListPrograms] = useState([{ titleProgramItem: "" }]);

  //? Requerimos nuestros estados globales
  const { itemid, matchitem, setMatchItem } = useContext(GetItemByIdContext);
  const { refreshgetuserid } = useContext(GetUserByIdContext);
  const { setRefreshGetItems, setRefreshGetPrivateItems } = useContext(
    GetItemsContext
  );

  useEffect(() => {
    if (match.params.id) {
      const dataItem = {
        titleItem: itemid.titleItem,
        categoryItem: itemid.categoryItem.id,
        subCategoryItem: itemid.subCategoryItem.id,
        programItem: itemid.programItem.id,
        colorTagCategoryItem: itemid.categoryItem.colorTagCategoryItem,
        categoryItemTitle: itemid.categoryItem.titleCategoryItem,
        authorItem: itemid.authorItem,
        descriptionItem: itemid.descriptionItem,
        fileNameItem: itemid.fileNameItem,
      };

      setItem(dataItem);
    } else {
      setMatchItem(null);
    }
  }, [match.params.id, itemid, setMatchItem]);

  //? Listamos todas las categorias existentes
  useEffect(() => {
    if (refreshcategory) {
      const fetchCaategories = async () => {
        try {
          const { data } = await API.graphql(graphqlOperation(listCategorys));
          const categories = data.listCategorys.items;

          setListCategories([{ titleCategoryItem: "" }].concat(categories));
          setRefreshCategory(false);
          setLoadingGetCategory(false);
        } catch (err) {
          setLoadingGetCategory(false);
          console.log(err);
        }
      };

      fetchCaategories();
    }
  }, [
    refreshcategory,
    setListCategories,
    setRefreshCategory,
    setLoadingGetCategory,
  ]);

  //? Listamos todas las subcategorias existentes
  useEffect(() => {
    if (refreshsubcategory) {
      const fetchSubCategories = async () => {
        try {
          const { data } = await API.graphql(
            graphqlOperation(listSubCategorys)
          );

          const subCategories = data.listSubCategorys.items;

          if (match.params.id) {
            const subcategoryItem = subCategories.filter((subcategory) => {
              return subcategory.categoryNameItemId === itemid.categoryItem.id;
            });

            setListSubCategories(
              [{ titleSubCategoryItem: "" }].concat(subcategoryItem)
            );
          }

          setGetSubCategories(subCategories);
          setRefreshSubCategory(false);
          setLoadingGetSubCategory(false);
        } catch (err) {
          setLoadingGetSubCategory(false);
          console.log(err);
        }
      };

      fetchSubCategories();
    }
  }, [
    refreshsubcategory,
    setListSubCategories,
    setGetSubCategories,
    setRefreshSubCategory,
    setLoadingGetSubCategory,
    match.params.id,
    itemid,
  ]);

  //? Listamos todos los programas existentes
  useEffect(() => {
    if (refreshprograms) {
      const fetchPrograms = async () => {
        try {
          const { data } = await API.graphql(graphqlOperation(listPrograms));
          const programs = data.listPrograms.items;

          if (match.params.id) {
            const programsItem = programs.filter(
              (program) =>
                program.subCategoryNameItemId === itemid.subCategoryItem.id
            );

            setListPrograms([{ titleProgramItem: "" }].concat(programsItem));
          }

          setGetPrograms(programs);
          setRefreshPrograms(false);
          setLoadignGetProgram(false);
        } catch (err) {
          setRefreshPrograms(false);
          setLoadignGetProgram(false);
          console.log(err);
        }
      };

      fetchPrograms();
    }
  }, [
    refreshprograms,
    setListPrograms,
    setGetPrograms,
    setRefreshPrograms,
    setLoadignGetProgram,
    match.params.id,
    itemid,
  ]);

  //? Definimos el tipo de accion a realizar en dynamo: crear o actualizar
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);

    if (!match.params.id) {
      if (selectedAction === "document") {
        const itemDocumentData = {
          titleItem: item.titleItem,
          authorItem: item.authorItem,
          descriptionItem: item.descriptionItem,
          itemCategoryItemId: item.categoryItem,
          itemSubCategoryItemId: item.subCategoryItem,
          itemProgramItemId: item.programItem,
          baseTypeItem: "items",
          accessItem: "private",
          fileItem: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key: "public/documents/" + item.fileItem.name.replace(/ /g, "_"),
          },
          fileNameItem: item.fileNameItem.replace(/ /g, "_"),
        };

        createDataItem(itemDocumentData);
      } else {
        const itemVideoData = {
          titleItem: item.titleItem,
          authorItem: item.authorItem,
          descriptionItem: item.descriptionItem,
          itemCategoryItemId: item.categoryItem,
          itemSubCategoryItemId: item.subCategoryItem,
          itemProgramItemId: item.programItem,
          baseTypeItem: "video",
          accessItem: "private",
          fileItem: {
            bucket: "",
            region: "",
            key: "",
          },
          fileNameItem: item.fileNameItem,
        };
        createDataItem(itemVideoData);
      }
    } else {
      const itemDataUpdate = {
        id: match.params.id,
        titleItem: item.titleItem,
        authorItem: item.authorItem,
        descriptionItem: item.descriptionItem,
        itemCategoryItemId: item.categoryItem,
        itemSubCategoryItemId: item.subCategoryItem,
        itemProgramItemId: item.programItem,
      };

      updateDataItem(itemDataUpdate);
    }
  };

  //? Almacenamos el item en dynamoDB
  const createDataItem = async (itemData) => {
    try {
      await API.graphql(graphqlOperation(createItem, { input: itemData }));

      selectedAction === "document"
        ? uploadDataItem()
        : history.push("/books-approval");
      setRefreshGetPrivateItems(true);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  };

  //? Almacenamos el item(documento) en s3
  const uploadDataItem = async () => {
    const { fileItem } = item;

    try {
      await Storage.put(
        `documents/${fileItem.name.replace(/ /g, "_")}`,
        fileItem,
        {
          contentType: `${fileItem.type}`,
        }
      );

      history.push("/books-approval");
      setRefreshGetPrivateItems(true);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  };

  //? Actualizmos el item en dynamoDB
  const updateDataItem = async (itemDataUpdates) => {
    try {
      await API.graphql(
        graphqlOperation(updateItem, { input: itemDataUpdates })
      );

      setRefreshGetPrivateItems(true);
      setRefreshGetItems(true);

      history.push(`${matchitem.path.slice(0, -4)}`);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      console.log(err);
    }
  };

  //? Actualizamos el estado de la aplicacion
  const handleChange = (e) => {
    const { name, value } = e.target;

    setItem({
      ...item,
      [name]: value,
    });
  };

  //? Actualizdos unicamente el estado del aechivo
  const handleChangeFile = (e) => {
    const file = e.target.files[0];

    setItem({
      ...item,
      fileItem: file,
      fileNameItem: file.name,
    });
  };

  const handleClick = (evt) => {
    const { value } = evt.target;

    const categoryItem = listcategories.find(
      (category) => category.id === value
    );
    const subcategoryItem = getsubcategories.filter((subcategory) => {
      return subcategory.categoryNameItemId === value;
    });

    setListSubCategories(
      [{ titleSubCategoryItem: "" }].concat(subcategoryItem)
    );

    setItem({
      ...item,
      categoryItemTitle: value === "" ? "" : categoryItem.titleCategoryItem,
      colorTagCategoryItem:
        value === "" ? "" : categoryItem.colorTagCategoryItem,
      categoryItem: value === "" ? "" : categoryItem.id,
    });
  };

  const handleClickSubCategories = (evt) => {
    const { value } = evt.target;

    const subCategoryItem = getsubcategories.find(
      (subcategory) => subcategory.id === value
    );
    const programs = getprograms.filter(
      (program) => program.subCategoryNameItemId === value
    );

    setListPrograms([{ titleProgramItem: "" }].concat(programs));
    setItem({
      ...item,
      subCategoryItem: value === "" ? "" : subCategoryItem.id,
    });
  };

  const handleClickPrograms = (evt) => {
    const { value } = evt.target;

    const programItem = getprograms.find((program) => program.id === value);

    setItem({
      ...item,
      programItem: value === "" ? "" : programItem.id,
    });
  };

  if (loading) return <Loading />;
  if (refreshgetuserid) return <Loading />;
  if (loadigetcategory) return <Loading />;
  if (loadigetsubcategory) return <Loading />;
  if (loadingetprogram) return <Loading />;

  return (
    <Fragment>
      {error ? (
        <Alert
          title="Ha ocurrido un error"
          body="Revise los datos y vuelva a intentar"
        />
      ) : (
        ""
      )}
      <AddItemForm
        item={item}
        category={listcategories}
        itemid={itemid}
        matchitem={matchitem}
        handleChange={handleChange}
        handleChangeFile={handleChangeFile}
        handleClick={handleClick}
        sendDataItem={handleSubmit}
        listsubcategories={listsubcategories}
        listprograms={listprograms}
        selectedAction={selectedAction}
        setSelectAction={setSelectAction}
        handleClickSubCategories={handleClickSubCategories}
        handleClickPrograms={handleClickPrograms}
      />
    </Fragment>
  );
};

export default AddItem;
