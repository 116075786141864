import React from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Container,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormRadio,
  Button,
  FormTextarea,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect,
} from "shards-react";

import PageTitle from "../common/PageTitle";

const AddItemForm = ({
  item,
  category,
  matchitem,
  handleChange,
  handleChangeFile,
  handleClick,
  sendDataItem,
  listsubcategories,
  listprograms,
  handleClickSubCategories,
  handleClickPrograms,
  selectedAction,
  setSelectAction,
}) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      {matchitem === null ? (
        <Link to="/books-approval">
          <Button pill>&larr; Volver</Button>
        </Link>
      ) : (
        <Link to={`${matchitem.url}`}>
          <Button pill>&larr; Volver</Button>
        </Link>
      )}

      <PageTitle
        title="Agregar / Modificar documents"
        md="12"
        className="ml-sm-auto mr-sm-auto mt-4"
      />
    </Row>
    <Row>
      <Col lg="4">
        <Card small className="card-post card-post--1 mb-4">
          <div
            className="card-post__image"
            style={{
              backgroundImage: `url(${require("../../images/content-management/item-background.png")})`,
            }}
          >
            <Badge
              pill
              className={`card-post__category bg-${item.colorTagCategoryItem}`}
            >
              {item.categoryItemTitle}
            </Badge>
            <div className="card-post__author d-flex">
              <span
                className="card-post__author-avatar card-post__author-avatar--small"
                style={{
                  backgroundImage: `url('${require("../../images/avatars/4.jpeg")}')`,
                }}
              />
            </div>
          </div>
          <CardBody>
            <h5 className="card-title">
              <span className="text-fiord-blue">{item.titleItem}</span>
            </h5>
            <p className="card-text d-inline-block mb-3">
              {item.descriptionItem}
            </p>
          </CardBody>
          <CardFooter className="text-muted border-top py-3">
            <span className="d-inline-block">
              Por <span className="text-fiord-blue">{item.authorItem}</span> en{" "}
              <span className="text-fiord-blue">{item.categoryItemTitle}</span>
            </span>
          </CardFooter>
        </Card>
      </Col>

      <Col lg="8">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">Datos generales del document</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={sendDataItem}>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="titleItem">Titulo</label>
                        <FormInput
                          id="titleItem"
                          name="titleItem"
                          value={item.titleItem}
                          onChange={handleChange}
                          required
                        />
                      </Col>

                      <Col md="6">
                        <label htmlFor="authorItem">Autor</label>
                        <FormInput
                          id="authorItem"
                          name="authorItem"
                          value={item.authorItem}
                          onChange={handleChange}
                          required
                        />
                      </Col>

                      <Col md="12" className="form-group">
                        <label htmlFor="descriptionItem">Descripción</label>
                        <FormTextarea
                          rows="2"
                          id="descriptionItem"
                          name="descriptionItem"
                          value={item.descriptionItem}
                          onChange={handleChange}
                          required
                        />
                      </Col>

                      <Col md="4" className="form-group">
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText>Dimensión</InputGroupText>
                          </InputGroupAddon>
                          <FormSelect
                            name="categoryItem"
                            onChange={handleChange}
                            value={item.categoryItem}
                            onClick={handleClick}
                            required
                          >
                            {category.map((category, id) => (
                              <option key={id} value={category.id}>
                                {category.titleCategoryItem}
                              </option>
                            ))}
                          </FormSelect>
                        </InputGroup>
                      </Col>

                      <Col md="4" className="form-group">
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText>Temática</InputGroupText>
                          </InputGroupAddon>
                          <FormSelect
                            name="subCategoryItem"
                            onChange={handleChange}
                            value={item.subCategoryItem}
                            onClick={handleClickSubCategories}
                            required
                          >
                            {listsubcategories.map((subcategory, id) => (
                              <option key={id} value={subcategory.id}>
                                {subcategory.titleSubCategoryItem}
                              </option>
                            ))}
                          </FormSelect>
                        </InputGroup>
                      </Col>

                      <Col md="4" className="form-group">
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText>Programa</InputGroupText>
                          </InputGroupAddon>
                          <FormSelect
                            name="programItem"
                            onChange={handleChange}
                            value={item.programItem}
                            onClick={handleClickPrograms}
                            required
                          >
                            {listprograms.map((program, id) => (
                              <option key={id} value={program.id}>
                                {program.titleProgramItem}
                              </option>
                            ))}
                          </FormSelect>
                        </InputGroup>
                      </Col>

                      {matchitem === null ? (
                        <Col md="5" className="form-group">
                          <FormRadio
                            inline
                            name="fileType"
                            checked={selectedAction === "document"}
                            onChange={() => {
                              const changeOption = (option) =>
                                setSelectAction(option);

                              changeOption("document");
                            }}
                          >
                            Documento
                          </FormRadio>

                          <FormRadio
                            inline
                            name="fileType"
                            checked={selectedAction === "video"}
                            onChange={() => {
                              const changeOption = (option) =>
                                setSelectAction(option);

                              changeOption("video");
                            }}
                          >
                            Video
                          </FormRadio>
                        </Col>
                      ) : (
                        ""
                      )}

                      {selectedAction === "video" ? (
                        <Col md="7" className="form-group">
                          <FormInput
                            name="fileNameItem"
                            placeholder="Link Youtube"
                            value={item.fileNameItem}
                            onChange={handleChange}
                            required
                          />
                        </Col>
                      ) : (
                        ""
                      )}

                      {matchitem === null && selectedAction === "document" ? (
                        <Col md="7" className="form-group">
                          <div className="custom-file mb-3">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="fileItem"
                              onChange={handleChangeFile}
                              required
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="fileItem"
                            >
                              {item.fileItem === null
                                ? "Cargar archivo"
                                : item.fileItem.name}
                            </label>
                          </div>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>

                    <Row>
                      <CardBody>
                        <div className="text-center">
                          <Button theme="primary" type="submit" pill>
                            Guardar
                          </Button>
                          {matchitem === null ? (
                            <Link to="/books-approval">
                              <Button theme="danger" pill className="ml-3">
                                Cancelar
                              </Button>
                            </Link>
                          ) : (
                            <Link to={`${matchitem.url}`}>
                              <Button theme="danger" pill className="ml-3">
                                Cancelar
                              </Button>
                            </Link>
                          )}
                        </div>
                      </CardBody>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  </Container>
);
export default AddItemForm;
