import React, { useState, useContext, useEffect } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";

import { GetUserByIdContext } from "../context/GetUserByIdContext";
import { GetUsersContext } from "../context/GetUsersContext";
import { Container, Row, Col } from "shards-react";
import { updateUser } from "../graphql/mutations";

import Alert from "../components/common/Alert";
import Loading from "../components/common/Loading";
import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/users/UserDetails";
import ChangePassword from "../components/users/ChangePassword";
import EditUserDetails from "../components/users/EditUserDetails";
import UserAccountDetails from "../components/users/UserAccountDetails";

const UserProfile = () => {
  //? Estado para editar perfil o password
  const [userdata, setUserData] = useState({
    username: "",
    firstName: "",
    lastName: "",
    birthday: "",
    gender: "",
    country: "",
    company: "",
    address: "",
    description: "",
  });
  const [change, setChange] = useState({
    user: false,
    password: false,
    show: false,
    oldPassword: "",
    newPassword: "",
  });

  //? Estado para informacion de errores
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({ message: "" });

  //? Estados globales de la aplicacion
  const { refreshgetusers, setRefreshGetUsers } = useContext(GetUsersContext);
  const {
    getuserbyid,
    refreshgetuserbyid,
    signupuserbyid,
    setRefreshGetUserById,
    setSignUpById,
  } = useContext(GetUserByIdContext);

  useEffect(() => {
    setUserData(getuserbyid);
  }, [setUserData, getuserbyid]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (change.user || signupuserbyid) {
      const userUpDates = {
        id: userdata.username,
        firstName: userdata.firstName,
        lastName: userdata.lastName,
        address: userdata.address,
        description: userdata.description,
        birthday: userdata.birthday,
        gender: userdata.gender,
        country: userdata.country,
        company: userdata.company,
      };

      updateUserInfo(userUpDates);
    } else {
      updateUserToCognito();
    }
  };

  const updateUserToCognito = async () => {
    const { oldPassword, newPassword } = change;

    try {
      const currentUser = await Auth.currentAuthenticatedUser();

      await Auth.changePassword(currentUser, oldPassword, newPassword);

      setChange({
        user: false,
        password: false,
        show: false,
      });
      setSignUpById(false);
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
      setInfo(err);
    }
  };

  const updateUserInfo = async (userUpdates) => {
    try {
      await API.graphql(graphqlOperation(updateUser, { input: userUpdates }));

      setLoading(false);
      setRefreshGetUsers(!refreshgetusers);
      setRefreshGetUserById(true);
      setSignUpById(false);

      setChange({
        user: false,
        password: false,
        show: false,
      });
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const handleChange = (evt) => {
    const { name, value } = evt.target;

    if (change.user || signupuserbyid) {
      setUserData({
        ...userdata,
        [name]: value,
      });
    } else {
      setChange({ ...change, [name]: value });
    }
  };

  const handleClick = () => {
    setSignUpById(false);
    setChange({
      user: false,
      password: false,
      show: false,
    });
  };

  if (loading) return <Loading />;
  if (refreshgetuserbyid) return <Loading />;

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Perfil de usuario"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      <Row>
        <Col lg="4">
          <UserDetails
            change={change}
            setChange={setChange}
            userdata={getuserbyid}
            setUserData={setUserData}
          />
        </Col>
        <Col lg="8">
          <UserAccountDetails userdata={getuserbyid} />
        </Col>
      </Row>

      {error ? (
        <Alert title="An unexpected error has occurred" body={info.message} />
      ) : (
        ""
      )}

      {change.password ? (
        <ChangePassword
          userdata={userdata}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleClick={handleClick}
        />
      ) : (
        ""
      )}

      {change.user || signupuserbyid ? (
        <EditUserDetails
          userdata={userdata}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleClick={handleClick}
        />
      ) : (
        ""
      )}
    </Container>
  );
};

export default UserProfile;
