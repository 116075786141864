import React, { useEffect, useContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Link } from "react-router-dom";

import { CardHeader, CardFooter } from "reactstrap";
import { Container, Row, Col, Card, CardBody, Button } from "shards-react";

import { listUsers } from "../graphql/queries";
import { GetUsersContext } from "../context/GetUsersContext";
import { GetUserByIdContext } from "../context/GetUserByIdContext";

import Loading from "../components/common/Loading";
import PageTitle from "../components/common/PageTitle";
import ListUsers from "../components/users/ListUsers";
import Menssage from "../components/common/Menssage";

const Users = () => {
  const { getuserbyid, refreshgetuserbyid } = useContext(GetUserByIdContext);

  const {
    getusers,
    setGetUsers,
    refreshgetusers,
    setRefreshGetUsers,
    loadergetusers,
    setLoaderGetUsers,
    setErrorGetUsers,
    contgetusers,
    setContGetUsers,
    contgetadminusers,
    setContGetAdminUsers,
  } = useContext(GetUsersContext);

  useEffect(() => {
    if (refreshgetusers) {
      const getResource = async () => {
        try {
          const { data } = await API.graphql(graphqlOperation(listUsers));
          const users = data.listUsers.items;

          const adminUsers = users.filter(
            (user) => user.userType === "administradores"
          );

          setGetUsers(adminUsers);
          setLoaderGetUsers(false);
          setRefreshGetUsers(!refreshgetusers);

          setContGetAdminUsers(adminUsers.length);
          setContGetUsers(users.length);
        } catch (err) {
          console.log(err);
          setLoaderGetUsers(false);
          setRefreshGetUsers(!refreshgetusers);
          setErrorGetUsers(err);
        }
      };

      getResource();
    } else {
      setLoaderGetUsers(false);
      setRefreshGetUsers(false);
    }
  }, [
    getuserbyid.userType,
    getusers,
    setGetUsers,
    refreshgetusers,
    setRefreshGetUsers,
    loadergetusers,
    setLoaderGetUsers,
    setContGetUsers,
    setContGetAdminUsers,
    setErrorGetUsers,
  ]);

  if (loadergetusers || refreshgetusers) return <Loading />;
  if (refreshgetuserbyid) return <Loading />;

  if (getuserbyid.userType === "administradores") {
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="10"
            title="Gestión de usuarios"
            className="text-sm-left"
          />

          <Col className="bottom-center">
            <Link to="/users/add-user">
              <Button pill sm="2">
                Agregar Administrador
              </Button>
            </Link>
          </Col>
        </Row>

        <Row>
          <Col lg="3" md="6" sm="12" className="mb-4">
            <Card small className="card text-center mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Administradores</h6>
              </CardHeader>
              <CardBody>
                <h1>{contgetadminusers}</h1>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>

          <Col lg="3" md="6" sm="12" className="mb-4">
            <Card small className="card text-center mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Lectores</h6>
              </CardHeader>
              <CardBody>
                <h1>{contgetusers}</h1>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>

          <ListUsers listUsers={getusers} />
        </Row>
      </Container>
    );
  } else {
    return <Menssage />;
  }
};
export default Users;
