import React, { useState, useEffect, useContext } from "react";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { Link } from "react-router-dom";

import Loading from "../common/Loading";
import { getItem } from "../../graphql/queries";
import { deleteItem, updateItem } from "../../graphql/mutations";

import { GetItemsContext } from "../../context/GetItemsContext";
import { GetItemByIdContext } from "../../context/GetItemByIdContext";
import { GetUserByIdContext } from "../../context/GetUserByIdContext";

import {
  Container,
  Row,
  Button,
  Badge,
  CardSubtitle,
  Col,
  CardHeader,
  CardText,
  CardTitle,
  Card,
} from "shards-react";
import "../../styles/CssViewsBooks.css";

const ViewBook = ({ match, history }) => {
  const [loading, setLoading] = useState(true);

  const { itemid, setItemid, matchitem, setMatchItem, setFile } = useContext(
    GetItemByIdContext
  );

  const {
    refreshgetitems,
    setRefreshGetItems,
    refreshgetprivateitems,
    setRefreshGetPrivateItems,
  } = useContext(GetItemsContext);

  const { getuserbyid, refreshgetuserbyid } = useContext(GetUserByIdContext);

  useEffect(() => {
    if (matchitem === null) {
      const getResourceById = async (itemId) => {
        try {
          const { data } = await API.graphql(
            graphqlOperation(getItem, { id: itemId })
          );

          const { fileItem } = data.getItem;
          const dataItem = data.getItem;

          setFile(fileItem);
          setItemid(dataItem);
          setMatchItem(match);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      };

      getResourceById(match.params.id);
    } else {
      setLoading(false);
    }
  }, [
    match,
    match.params.id,
    matchitem,
    setFile,
    setItemid,
    setMatchItem,
    setLoading,
  ]);

  const approveItem = async (itemApprove) => {
    setLoading(true);

    try {
      await API.graphql(graphqlOperation(updateItem, { input: itemApprove }));

      history.push(`${match.path.slice(0, -4)}`);
      setRefreshGetItems(true);
      setRefreshGetPrivateItems(!refreshgetprivateitems);
      console.log("updated completed success");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const deleteItemId = async (_itemid) => {
    setLoading(true);

    try {
      await API.graphql(graphqlOperation(deleteItem, { input: _itemid }));

      setRefreshGetPrivateItems(!refreshgetprivateitems);
      setRefreshGetItems(!refreshgetitems);

      history.push(`${match.path.slice(0, -4)}`);
      console.log("delete completed success");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  if (loading) return <Loading />;
  if (refreshgetuserbyid) return <Loading />;

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <Link to={`${match.path.slice(0, -4)}`}>
          <Button pill>&larr; Volver</Button>
        </Link>
      </Row>
      <Row>
        <Col lg="4">
          <Card>
            <div
              className="card-post__image card-post--1"
              style={{
                backgroundImage: `url(https://${itemid.categoryItem.imageCategoryItem.bucket}.s3.${itemid.categoryItem.imageCategoryItem.region}.amazonaws.com/${itemid.categoryItem.imageCategoryItem.key})`,
                cursor: "pointer",
              }}
            >
              <Badge
                pill
                className={`card-post__category bg-${itemid.categoryItem.colorTagCategoryItem}`}
              >
                {itemid.categoryItem.titleCategoryItem}
              </Badge>
            </div>
          </Card>
        </Col>
        <Col lg="8">
          <CardHeader className="rounded">
            <CardTitle>{itemid.titleItem}</CardTitle>
            <CardSubtitle className="py-2">{itemid.authorItem}</CardSubtitle>
            <CardText className="card-text text-justify">
              {itemid.descriptionItem}
            </CardText>

            <div className="text-center ">
              {getuserbyid.userType === "administradores" ? (
                <Link to={`${match.url}/edit`}>
                  <Button
                    lg="4"
                    className=" m-1 radio"
                    outline
                    pill
                    title="Editar"
                    theme="light"
                  >
                    <span className="material-icons edit">edit</span>
                  </Button>
                </Link>
              ) : (
                ""
              )}

              {itemid.baseTypeItem !== "video" ? (
                <Button
                  lg="4"
                  className=" m-1 radio"
                  outline
                  pill
                  theme="light"
                  title="Descargar"
                  onClick={() => {
                    Storage.get("documents/" + itemid.fileNameItem)
                      .then((result) => {
                        window.open(result, "_blank");
                      })
                      .catch((err) => console.log(err));
                  }}
                >
                  <span className="material-icons download">
                    cloud_download
                  </span>
                </Button>
              ) : (
                ""
              )}

              <Link to={`${match.url}/view-book`}>
                <Button
                  lg="4"
                  className="m-1 radiodelete"
                  outline
                  pill
                  title="Ver"
                  theme="light"
                >
                  <span className="material-icons view">visibility</span>
                </Button>
              </Link>

              {itemid.accessItem === "private" ? (
                <Button
                  lg="4"
                  className="m-1 radio"
                  outline
                  pill
                  title="Aprobar"
                  theme="light"
                  onClick={() => {
                    const aprroveItem = {
                      id: match.params.id,
                      accessItem: "public",
                    };
                    approveItem(aprroveItem);
                  }}
                >
                  <span className="material-icons aprovate">done</span>
                </Button>
              ) : (
                ""
              )}

              {getuserbyid.userType === "administradores" ? (
                <Button
                  className="m-1 radiodelete "
                  lg="4"
                  outline
                  pill
                  theme="light"
                  title="Eliminar"
                  onClick={() => {
                    const delItemId = { id: match.params.id };
                    const response = window.confirm(
                      "Deseas eleminar este Documento?"
                    );

                    if (response) {
                      Storage.remove("documents/" + itemid.fileNameItem)
                        .then(() => deleteItemId(delItemId))
                        .catch((err) => console.log(err));
                    }
                  }}
                >
                  <span className="material-icons delete">delete</span>
                </Button>
              ) : (
                ""
              )}
            </div>
          </CardHeader>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewBook;
