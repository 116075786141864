import React, { Fragment, useState, useContext } from "react";
import { GetUserByIdContext } from "../context/GetUserByIdContext";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { createUser } from "../graphql/mutations";

import SignUpForm from "./SignUpForm";
import Loading from "./common/Loading";
import Alert from "./common/Alert";

const SignUp = ({ history }) => {
  const initialState = {
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    phone_number: "",
    password: "",
    confirmpass: "",
    confirmationCode: "",
    showpass: false,
    showconfirmpass: false,
    verified: false,
  };

  const [form, setForm] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [info, setInfo] = useState({ message: "" });

  const { setGetUserById, setRefreshGetUserById, setSignUpById } = useContext(
    GetUserByIdContext
  );

  const handleSubmit = (evt) => {
    const { verified } = form;
    setLoading(true);
    evt.preventDefault();

    verified ? confirmUserToCognito() : createUserToCognito();
  };

  const createUserToCognito = async () => {
    const { username, password, email, phone_number } = form;

    try {
      await Auth.signUp({
        username: username,
        password: password,
        attributes: {
          email: email,
          phone_number: "+502" + phone_number,
        },
      });

      setError(false);
      setForm({
        ...form,
        verified: true,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      setInfo(err);
    }
  };

  const confirmUserToCognito = async () => {
    const { username, confirmationCode } = form;

    try {
      await Auth.confirmSignUp(username, confirmationCode);

      setGetUserById(form);
      signInToApp();
    } catch (err) {
      setLoading(false);
      setError(true);
      setInfo(err);
    }
  };

  const signInToApp = async () => {
    const {
      username,
      firstName,
      lastName,
      email,
      phone_number,
      password,
    } = form;

    const userObject = {
      id: username,
      username: username,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phone_number,
      address: "",
      description: "",
      baseTypeUser: "usuarios",
      userType: "lectores",
    };

    try {
      await Auth.signIn(username, password);
      addUserToDynamoDB(userObject);
    } catch (err) {
      setLoading(false);
      setError(true);
      setInfo(err);
    }
  };

  const addUserToDynamoDB = async (user) => {
    try {
      await API.graphql(graphqlOperation(createUser, { input: user }));

      history.push("/user-profile");
      setSignUpById(true);
      setRefreshGetUserById(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      setInfo(err);
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setForm({
      ...form,
      showpass: !form.showpass,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setForm({
      ...form,
      showconfirmpass: !form.showconfirmpass,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  if (loading) return <Loading />;
  return (
    <Fragment>
      {error ? (
        <Alert title="An unexpected error has occurred" body={info.message} />
      ) : (
        ""
      )}

      <SignUpForm
        form={form}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onClickShowPass={handleClickShowPassword}
        onClickShowConfirmPass={handleClickShowConfirmPassword}
        onMouseDown={handleMouseDownPassword}
      />
    </Fragment>
  );
};

export default SignUp;
