import React, { useState, useEffect, Fragment } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import AddProgramasForm from "./AddProgramasForm";
import { createProgram, updateProgram } from "../../graphql/mutations";
import { listSubCategorys, getProgram } from "../../graphql/queries";
import awsExports from "../../aws-exports";
import Loading from "../common/Loading";

const AddProgramas = ({ match, history }) => {
  const initialState = {
    //? Estado inicial del formulario
    imagePromgramLinkItem: require("../../images/content-management/13.jpeg"),
    imageProgramItem: null,
    imageNameProgramItem: "",
    titleProgramItem: "Programa",
    colorTagProgramItem: "warning",
    categoryNameItemId: "",
    subCategoryNameItemId: "",
    descriptionProgramItem:
      "However venture pursuit he am mr cordial. Forming musical am hearing studied be luckily. But in for determine what would see",
  };

  const [loading, setLoading] = useState(true);
  const [changeImage, setChangeImage] = useState(false);
  const [imageDelete, setImageDelete] = useState("");
  const [loadingetprogram, setLoadinGetProgram] = useState(true);
  const [refreshgetsubcategories, setRefreshGetSubCategories] = useState(true);
  const [subcategories, setSubCategories] = useState();
  const [program, setProgram] = useState(initialState); //? Estado del formulario

  useEffect(() => {
    //? Traemos los datos del programa para reemplazar el estado inicial
    if (match.params.id) {
      const getProgramById = async (idProgram) => {
        const { data } = await API.graphql(
          graphqlOperation(getProgram, { id: idProgram })
        );

        const program = data.getProgram;

        setImageDelete(program.imageNameProgramItem);
        setProgram(program);
        setLoadinGetProgram(false);
      };

      getProgramById(match.params.id);
    } else {
      setLoadinGetProgram(false);
    }
  }, [match.params.id, setProgram, setLoadinGetProgram]);

  useEffect(() => {
    //? Listamos todas las subcaterias existentes
    if (refreshgetsubcategories) {
      const getSubCategories = async () => {
        try {
          const { data } = await API.graphql(
            graphqlOperation(listSubCategorys)
          );

          const subCategories = data.listSubCategorys.items;

          setSubCategories(subCategories);
          setRefreshGetSubCategories(false);
          setLoading(false);
        } catch (err) {
          setRefreshGetSubCategories(false);
          setLoading(false);
          console.log(err);
        }
      };

      getSubCategories();
    }
  }, [refreshgetsubcategories, setRefreshGetSubCategories]);

  //? Definiremos el tipo de accion que necesitamos realizar, si crear o actualizar
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);

    if (!match.params.id) {
      const programObject = {
        titleProgramItem: program.titleProgramItem,
        colorTagProgramItem: program.colorTagProgramItem,
        descriptionProgramItem: program.descriptionProgramItem,
        programCategoryProgramItemId: program.categoryNameItemId,
        programSubCategoryProgramItemId: program.subCategoryNameItemId,
        imageProgramItem: {
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          key:
            "public/images/" + program.imageNameProgramItem.replace(/ /g, ""),
        },
        imageNameProgramItem: program.imageNameProgramItem.replace(/ /g, ""),
        baseTypeProgramItem: "programas",
        categoryNameItemId: program.categoryNameItemId,
        subCategoryNameItemId: program.subCategoryNameItemId,
      };

      createDataToDB(programObject);
    } else {
      if (changeImage) {
        const updateProgramObject = {
          id: match.params.id,
          titleProgramItem: program.titleProgramItem,
          colorTagProgramItem: program.colorTagProgramItem,
          descriptionProgramItem: program.descriptionProgramItem,
          baseTypeProgramItem: program.baseTypeProgramItem,
          programCategoryProgramItemId: program.categoryNameItemId,
          programSubCategoryProgramItemId: program.subCategoryNameItemId,
          categoryNameItemId: program.categoryNameItemId,
          subCategoryNameItemId: program.subCategoryNameItemId,
          imageProgramItem: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key:
              "public/images/" + program.imageNameProgramItem.replace(/ /g, ""),
          },
          imageNameProgramItem: program.imageNameProgramItem.replace(/ /g, ""),
        };

        updateImageToS3(updateProgramObject);
      } else {
        const updateProgramObject = {
          id: match.params.id,
          titleProgramItem: program.titleProgramItem,
          colorTagProgramItem: program.colorTagProgramItem,
          descriptionProgramItem: program.descriptionProgramItem,
          baseTypeProgramItem: program.baseTypeProgramItem,
          programCategoryProgramItemId: program.categoryNameItemId,
          programSubCategoryProgramItemId: program.subCategoryNameItemId,
          categoryNameItemId: program.categoryNameItemId,
          subCategoryNameItemId: program.subCategoryNameItemId,
        };

        updateProgramData(updateProgramObject);
      }
    }
  };

  //? Almacenaremos el programa en dynamosDB
  const createDataToDB = async (dataProgram) => {
    try {
      await API.graphql(
        graphqlOperation(createProgram, { input: dataProgram })
      );

      createDataToS3(dataProgram);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //? Amacenaremos la imagen del programa en s3
  const createDataToS3 = async () => {
    try {
      await Storage.put(
        `images/${program.imageNameProgramItem.replace(/ /g, "")}`,
        program.imageProgramItem,
        {
          contentType: `${program.imageProgramItem.type}`,
        }
      );

      history.push("/books-category");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //? Actualizaremos los datos del programa
  const updateProgramData = async (updateDataProgram) => {
    try {
      await API.graphql(
        graphqlOperation(updateProgram, { input: updateDataProgram })
      );

      history.push("/books-category");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const updateImageToS3 = async (updateProgramObject) => {
    try {
      await Storage.remove("images/" + imageDelete);

      await Storage.put(
        `images/${program.imageNameProgramItem.replace(/ /g, "")}`,
        program.imageProgramItem,
        {
          contentType: `${program.imageProgramItem.type}`,
        }
      );

      console.log("image updated");
      updateProgramData(updateProgramObject);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //? Actualizaremos el estado del componente
  const handleChange = (evt) => {
    const { name, value } = evt.target;

    setProgram({
      ...program,
      [name]: value,
    });
  };

  //? Actualizaremso unicamente el estado de la imagen
  const handleChangeImage = (evt) => {
    const image = evt.target.files[0];

    if (match.params.id) setChangeImage(true);

    setProgram({
      ...program,
      imagePromgramLinkItem: URL.createObjectURL(image),
      imageNameProgramItem: image.name,
      imageProgramItem: image,
    });
  };

  //? Definiremos hacia que tipo de subcategoria pertenecera este programa
  const handleClick = (evt) => {
    const { value } = evt.target;

    const categoryItemId = subcategories.find(
      (category) => category.id === value
    );

    setProgram({
      ...program,
      categoryNameItemId: value === "" ? "" : categoryItemId.categoryItem.id,
      subCategoryNameItemId: value === "" ? "" : categoryItemId.id,
    });
  };

  if (loading) return <Loading />;
  if (refreshgetsubcategories) return <Loading />;
  if (loadingetprogram) return <Loading />;

  return (
    <Fragment>
      <AddProgramasForm
        program={program}
        match={match}
        changeImage={changeImage}
        subcategories={subcategories}
        handleClick={handleClick}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleChangeImage={handleChangeImage}
      />
    </Fragment>
  );
};

export default AddProgramas;
