import React from "react";
import { Container, Button } from "shards-react";
import { Link } from "react-router-dom";

const Menssage = () => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        <h3>Sección administrativa</h3>
        <p>Esta sección es unicamente para usuarios administradores</p>
        <Link to="/books-overview">
          <Button pill>&larr; Ir a biblioteca</Button>
        </Link>
      </div>
    </div>
  </Container>
);

export default Menssage;
