import React from "react";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { CardSubtitle } from "shards-react";
import { ShowPass } from "./common/ShowPass";
import { ShowConfirmPass } from "./common/ShowPass";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  Redes: {
    marginTop: theme.spacing(3),
    borderRadius: "30px",
    height: "75%",
  },

  form: {
    width: "100%",
  },

  submit: {
    margin: theme.spacing(0, 0, 4),
    borderRadius: "30px",
    height: "68%",
  },

  divisor: {
    marginTop: theme.spacing(5),
    textAlign: "center",
  },

  divisor2: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },

  title: {
    marginTop: theme.spacing(2),
  },

  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  link: {
    marginTop: theme.spacing(4),
  },

  chek: {
    marginTop: theme.spacing(2),
  },

  inputs: {
    marginTop: theme.spacing(3),
  },
}));

const SignUpForm = ({
  form,
  onSubmit,
  onChange,
  onClickShowPass,
  onClickShowConfirmPass,
  onMouseDown,
}) => {
  const classes = useStyles();

  if (form.verified) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Verificación
          </Typography>
          <CardSubtitle
            style={{
              top: "15px",
              position: "relative",
              marginBottom: " 40px",
            }}
          >
            {" "}
            Se le envio un correo de confirmacion
          </CardSubtitle>
          <form className={classes.form} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="fname"
                  name="username"
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="Usuario"
                  value={form.username}
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  autoComplete="fcode"
                  name="confirmationCode"
                  variant="outlined"
                  required
                  fullWidth
                  id="confirmationCode"
                  label="Codigo de verificación"
                  onChange={onChange}
                  autoFocus
                />
              </Grid>
              <Grid xs="12">
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    );
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div style={{ width: "40%", height: "40%" }}>
          <img
            className="card-img-top"
            alt=""
            src={require("../images/Logo-biblioteca.png")}
          />
        </div>

        <Typography component="h1" variant="h5" className={classes.title}>
          Registrarse
        </Typography>

        <Grid container>
          <Grid item xs={5}>
            <Divider className={classes.divisor} />
          </Grid>

          <Grid item xs={2}>
            <p className={classes.divisor2}>o</p>
          </Grid>

          <Grid item xs={5}>
            <Divider className={classes.divisor} />
          </Grid>
        </Grid>

        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="fname"
                name="username"
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Usuario"
                value={form.username}
                onChange={onChange}
                autoFocus
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Nombre"
                name="firstName"
                value={form.firstName}
                autoComplete="firstName"
                onChange={onChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Apellido"
                name="lastName"
                value={form.lastName}
                autoComplete="lastName"
                onChange={onChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                value={form.email}
                autoComplete="email"
                onChange={onChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={clsx(classes.margin, classes.textField)}
              >
                <ShowPass
                  show={form}
                  onChangeShow={onChange}
                  onClickShow={onClickShowPass}
                  onMouseDown={onMouseDown}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={clsx(classes.margin, classes.textField)}
              >
                <ShowConfirmPass
                  show={form}
                  onChangeShow={onChange}
                  onClickShow={onClickShowConfirmPass}
                  onMouseDown={onMouseDown}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phone_number"
                label="Número de teléfono"
                name="phone_number"
                value={form.phone_number}
                autoComplete="phone_number"
                onChange={onChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="Recibir notificaciones"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                style={{ outline: "none" }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Registrarte
              </Button>
            </Grid>
          </Grid>

          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/signin" variant="body2">
                ¿Ya tienes cuenta? Inicia sesión
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default SignUpForm;
