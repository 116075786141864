import React from "react";

const MenssageCarrusell = () => (
  <div className="slide-menssage-categories">
    <div className="error__content">
      <h3>Sección Vacia</h3>
      <p>Esta sección aun no tiene contenido </p>
    </div>
  </div>
);

export default MenssageCarrusell;
