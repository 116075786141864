import React, { useState, useEffect, Fragment, useContext } from "react";
import { createCategory, updateCategory } from "../../graphql/mutations";
import { GetCategoryContext } from "../../context/GetCategoryContext";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { getCategory } from "../../graphql/queries";

import AddCategoryForm from "./AddCategoryForm";
import awsExports from "../../aws-exports";
import Loading from "../common/Loading";
import Alert from "../common/Alert";

const AddCategory = ({ match, history }) => {
  //? Definimos un estado inicial para el formulario
  const initialState = {
    imageCategoryLinkItem: require("../../images/content-management/13.jpeg"),
    imageCategoryItem: null,
    nameImageCategoryItem: "",
    titleCategoryItem: "Dimensión",
    colorTagCategoryItem: "warning",
    descriptionCategoryItem:
      "However venture pursuit he am mr cordial. Forming musical am hearing studied be luckily. But in for determine what would see",
  };

  const [category, setCategory] = useState(initialState);
  const [changeImage, setChangeImage] = useState(false);
  const [imageDelete, setImageDelete] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  //? Importamos estados globales
  const { refreshgetcategory, setRefreshGetCategory } = useContext(
    GetCategoryContext
  );

  //? Obtenemos los datos de la categoria actual para editarla
  useEffect(() => {
    if (match.params.id) {
      const getCategotyById = async (categoryId) => {
        try {
          const { data } = await API.graphql(
            graphqlOperation(getCategory, { id: categoryId })
          );
          const category = data.getCategory;

          setImageDelete(category.imageNameCategoryItem);
          setCategory(category);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      };

      getCategotyById(match.params.id);
    } else {
      setLoading(false);
    }
  }, [match.params.id, setCategory]);

  //? Enviamos los datos hacia dynamoDB
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);

    if (!match.params.id) {
      const categoryDataObject = {
        titleCategoryItem: category.titleCategoryItem,
        colorTagCategoryItem: category.colorTagCategoryItem,
        descriptionCategoryItem: category.descriptionCategoryItem,
        baseTypeCategoryItem: "categorias",
        imageCategoryItem: {
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          key:
            "public/images/" + category.nameImageCategoryItem.replace(/ /g, ""),
        },
        imageNameCategoryItem: category.nameImageCategoryItem.replace(/ /g, ""),
      };

      createCategoryToDynamoDB(categoryDataObject);
    } else {
      if (changeImage) {
        const categoryUpdateDataObject = {
          id: match.params.id,
          titleCategoryItem: category.titleCategoryItem,
          colorTagCategoryItem: category.colorTagCategoryItem,
          descriptionCategoryItem: category.descriptionCategoryItem,
          imageCategoryItem: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key:
              "public/images/" +
              category.nameImageCategoryItem.replace(/ /g, ""),
          },
          imageNameCategoryItem: category.nameImageCategoryItem.replace(
            / /g,
            ""
          ),
        };

        updateImageToS3(categoryUpdateDataObject);
      } else {
        updateCategoryData({
          id: match.params.id,
          titleCategoryItem: category.titleCategoryItem,
          colorTagCategoryItem: category.colorTagCategoryItem,
          descriptionCategoryItem: category.descriptionCategoryItem,
        });
      }
    }
  };

  //? Creamos la nueva categoria en dynamoDB
  const createCategoryToDynamoDB = async (categoryData) => {
    try {
      await API.graphql(
        graphqlOperation(createCategory, { input: categoryData })
      );

      uploadCategoryImageToS3();
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  //? Actualizamos los datos en dynamoDB de la categoria seleccionada
  const updateCategoryData = async (categoryUpdateData) => {
    try {
      await API.graphql(
        graphqlOperation(updateCategory, { input: categoryUpdateData })
      );

      setRefreshGetCategory(true);
      history.push("/books-category");
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  //? Guarmados la imagen de la categoria en s3
  const uploadCategoryImageToS3 = async () => {
    try {
      await Storage.put(
        `images/${category.nameImageCategoryItem.replace(/ /g, "")}`,
        category.imageCategoryItem,
        {
          contentType: `${category.imageCategoryItem.type}`,
        }
      );

      history.push("/books-category");
      setLoading(false);
      setRefreshGetCategory(!refreshgetcategory);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  const updateImageToS3 = async (categoryUpdateDataObject) => {
    try {
      await Storage.remove("images/" + imageDelete);

      await Storage.put(
        `images/${category.nameImageCategoryItem.replace(/ /g, "")}`,
        category.imageCategoryItem,
        {
          contentType: `${category.imageCategoryItem.type}`,
        }
      );

      console.log("image updated");
      updateCategoryData(categoryUpdateDataObject);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //? Actualizamos el estado del componente
  const handleChange = (e) => {
    setCategory({
      ...category,
      [e.target.name]: e.target.value,
    });
  };

  //? Actualizmos el cambio de estado unicamente de la imagen
  const handleChangeImage = (e) => {
    const image = e.target.files[0];

    if (match.params.id) setChangeImage(true);

    setCategory({
      ...category,
      imageCategoryLinkItem: URL.createObjectURL(image),
      imageCategoryItem: image,
      nameImageCategoryItem: image.name,
    });
  };

  if (loading) return <Loading />;

  return (
    <Fragment>
      {error ? (
        <Alert
          title="Ha ocurrido un error"
          body="Revise los campos y vuelva a intentar"
        />
      ) : (
        ""
      )}
      <AddCategoryForm
        match={match}
        category={category}
        changeImage={changeImage}
        handleChange={handleChange}
        handleChangeImage={handleChangeImage}
        handleSubmit={handleSubmit}
      />
    </Fragment>
  );
};

export default AddCategory;
