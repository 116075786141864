import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "shards-react";

const Alert = ({ title, body }) => {
  const [error, setError] = useState(true);

  return (
    <Modal open={error} toggle={() => setError(!error)}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{body}</ModalBody>
    </Modal>
  );
};

export default Alert;
