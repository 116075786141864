import React, { Fragment } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const ShowPass = ({ show, onChangeShow, onClickShow, onMouseDownShow }) => (
  <Fragment>
    <InputLabel
      htmlFor="password"
    >
      Contraseña
    </InputLabel>
    <OutlinedInput
      required
      label="Contraseña"
      fullWidth
      id="password"
      name="password"
      type={show.showpass ? 'text' : 'password'}
      value={show.password}
      onChange={onChangeShow}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={onClickShow}
            onMouseDown={onMouseDownShow}
            edge="end"
          >
            {
              show.showpass
                ? <Visibility />
                : <VisibilityOff />
            }
          </IconButton>
        </InputAdornment>
      }
      labelWidth={175}
    />
  </Fragment>
);

const ShowConfirmPass = ({ show, onChangeShow, onClickShow, onMouseDownShow }) => (
  <Fragment>
    <InputLabel
      htmlFor="confirmpass"
    >
      Confirmar
    </InputLabel>
    <OutlinedInput
      required
      label="Confirmar"
      fullWidth
      id="confirmpass"
      name="confirmpass"
      type={show.showconfirmpass ? 'text' : 'password'}
      value={show.confirmpass}
      onChange={onChangeShow}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={onClickShow}
            onMouseDown={onMouseDownShow}
            edge="end"
          >
            {
              show.showconfirmpass
                ? <Visibility />
                : <VisibilityOff />
            }
          </IconButton>
        </InputAdornment>
      }
      labelWidth={175}
    />
  </Fragment>
)


export { ShowPass, ShowConfirmPass };