import React, { useState, useEffect, createContext } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getUser } from "../graphql/queries";
import { Auth } from "aws-amplify";

export const GetUserByIdContext = createContext();

export const GetUserByIdProvider = (props) => {
  const [getuserbyid, setGetUserById] = useState({});
  const [signupuserbyid, setSignUpById] = useState(false);
  const [refreshgetuserbyid, setRefreshGetUserById] = useState(true);
  const [loadergetuserbyid, setLoaderGetUserById] = useState(false);

  useEffect(() => {
    if (refreshgetuserbyid) {
      Auth.currentUserInfo()
        .then((user) => fetchUserId(user.username))
        .catch(() => {
          return;
        });

      const fetchUserId = async (userNameId) => {
        try {
          const getUserById = await API.graphql(
            graphqlOperation(getUser, { id: userNameId })
          );

          const data = getUserById.data.getUser;
          setGetUserById(data);
          setRefreshGetUserById(!refreshgetuserbyid);
        } catch (err) {
          setRefreshGetUserById(!refreshgetuserbyid);
          return;
        }
      };
    }
  }, [setGetUserById, refreshgetuserbyid, setRefreshGetUserById]);

  return (
    <GetUserByIdContext.Provider
      value={{
        getuserbyid,
        setGetUserById,
        refreshgetuserbyid,
        setRefreshGetUserById,
        loadergetuserbyid,
        setLoaderGetUserById,
        signupuserbyid,
        setSignUpById,
      }}
    >
      {props.children}
    </GetUserByIdContext.Provider>
  );
};
