import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { GetCategoryContext } from "../../context/GetCategoryContext";
import { listPrograms } from "../../graphql/queries";
import { deleteSubCategory } from "../../graphql/mutations";
import { API, graphqlOperation, Storage } from "aws-amplify";

import { Row, Col, Card, CardBody, Badge, Button } from "shards-react";
import Carousel from "react-elastic-carousel";
import PageTitle from "../common/PageTitle";
import Loading from "../common/Loading";

const ViewSubCategory = ({ getuserbyid, breakPoints }) => {
  const [loading, setLoading] = useState(false);

  //? Mandamos a llamar el estado global de categorias
  const {
    getsubcategory,
    setGetProgram,
    setRefreshGetProgram,
    viewsubcategory,
    setViewSubCategory,
  } = useContext(GetCategoryContext);

  //? Obtenenos los programas perteneciente a la subcategoria seleccionada
  const getPrograms = async (idProgram) => {
    setRefreshGetProgram(true);

    try {
      const fetchData = await API.graphql(
        graphqlOperation(listPrograms, {
          filter: {
            subCategoryNameItemId: {
              eq: `${idProgram}`,
            },
          },
        })
      );

      const data = fetchData.data.listPrograms.items;

      setGetProgram(data);
      setRefreshGetProgram(false);
    } catch (err) {
      setRefreshGetProgram(false);
      console.log(err);
    }
  };

  //? Eliminamos la subcategoria de dynamoDB
  const deleteSuCategoryToDB = async (subCategoryId) => {
    setLoading(true);

    const dataSubCategory = getsubcategory.find(
      (subcategory) => subcategory.id === subCategoryId.id
    );

    console.log(dataSubCategory);

    try {
      await API.graphql(
        graphqlOperation(deleteSubCategory, { input: subCategoryId })
      );

      console.log("delete to db completed success");
      deleteSubCategoryToS3(dataSubCategory.imageNameSubCategoryItem);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //? Eliminamos la imagen de la subcategoria en s3
  const deleteSubCategoryToS3 = async (imageNameSubCategoryItem) => {
    try {
      await Storage.remove("images/" + imageNameSubCategoryItem);
      setViewSubCategory(!viewsubcategory);
      setLoading(false);
      console.log("delete to s3 completed success");
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  if (loading) return <Loading />;

  //? Renderizamos los resultados obtenidos
  return (
    <div className=" slide-tematica effectTematica">
      <Row noGutters className="page-header py-4">
        <Col lg="10">
          <PageTitle sm="4" title="Temáticas" className="text-sm-left" />
        </Col>
        {getuserbyid.userType === "administradores" ? (
          <Col lg="2" className="bottom-center">
            <Link to="/books-category/add-subcategory">
              <span className="btn btn-primary rounded-pill">
                Agregar Temática
              </span>
            </Link>
          </Col>
        ) : (
          ""
        )}
      </Row>

      <Row>
        <Carousel
          breakPoints={breakPoints}
          className="size slide-bottom separator effect"
        >
          {getsubcategory.map((subcategory, id) => (
            <Col lg="12" md="12" sm="12" className="mb-4 pruebaI" key={id}>
              <Card small className="card-post card-post--1 pruebaII">
                <div
                  className="card-post__image card-post--1"
                  style={{
                    backgroundImage: `url(https://${subcategory.imageSubCategoryItem.bucket}.s3.${subcategory.imageSubCategoryItem.region}.amazonaws.com/${subcategory.imageSubCategoryItem.key})`,
                    cursor: "pointer",
                  }}
                >
                  <Badge
                    pill
                    className={`card-post__category bg-${subcategory.colorSubCategoryThemeItem}`}
                  >
                    {subcategory.titleSubCategoryItem}
                  </Badge>
                </div>

                <CardBody>
                  <div className="space-btn">
                    {getuserbyid.userType === "administradores" ? (
                      <Link
                        to={`/books-category/add-subcategory/${subcategory.id}`}
                      >
                        <Button
                          className="card-botton-size "
                          outline
                          pill
                          theme="light"
                          title="Editar"
                        >
                          <span className="material-icons edit-position-bottom-card">
                            edit
                          </span>
                        </Button>
                      </Link>
                    ) : (
                      ""
                    )}
                    <Link to="/books-category/programs">
                      <Button
                        outline
                        pill
                        theme="light"
                        title="Ver"
                        className="card-botton-size"
                        onClick={() => {
                          const programId = subcategory.id;

                          getPrograms(programId);
                        }}
                      >
                        <span className="material-icons view-position-bottom-card">
                          visibility
                        </span>
                      </Button>
                    </Link>

                    {getuserbyid.userType === "administradores" ? (
                      <Button
                        outline
                        pill
                        theme="light"
                        title="Eliminar"
                        className="card-botton-size"
                        onClick={() => {
                          const subCategoryId = { id: subcategory.id };
                          const response = window.confirm(
                            "Deseas eleminar esta Temática?"
                          );

                          if (response) deleteSuCategoryToDB(subCategoryId);
                        }}
                      >
                        <span className="material-icons delete-position-bottom-card">
                          delete
                        </span>
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
                <div className="text body-scroll body-scroll-container">
                  <p className="scrollbox-content">
                    {subcategory.descriptionSubCategoryItem}
                  </p>
                </div>
              </Card>
            </Col>
          ))}
        </Carousel>
      </Row>
    </div>
  );
};

export default ViewSubCategory;
