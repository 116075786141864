import React from "react";
import { Container, Row, Card, Button } from "shards-react";
import PageTitle from "../common/PageTitle";
import { Link } from "react-router-dom";

const ListItems = ({ dataItem }) => {
  return (
    <Container fluid className="space">
      {/** Pending documents section */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Documentos pendientes"
          md="12"
          className="ml-sm-auto mr-sm-auto mt-4"
        />
      </Row>

      <Card>
        <div className="table-responsive-xl ">
          <table className="table ">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Título</th>
                <th scope="col">Autor</th>
                <th scope="col">Dimensión</th>
                <th scope="col">Temática</th>
                <th scope="col">Programa</th>
              </tr>
            </thead>
            <tbody>
              {dataItem.map((data, id) => (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{data.titleItem}</td>
                  <td>{data.authorItem}</td>
                  <td>{data.categoryItem.titleCategoryItem}</td>
                  <td>{data.subCategoryItem.titleSubCategoryItem}</td>
                  <td>{data.programItem.titleProgramItem}</td>
                  <td className="SpaceTable">
                    <Link to={`/books-approval/${data.id}`}>
                      <Button
                        lg="4"
                        size="sm"
                        className="visibility Table"
                        outline
                        pill
                        title="Ver"
                        theme="light"
                      >
                        <span className="material-icons viewTable">
                          visibility
                        </span>
                      </Button>
                    </Link>
                  </td>
                  <td className="SpaceTable"></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </Container>
  );
};

export default ListItems;
