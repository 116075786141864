import React, { useContext } from "react";
import {
  Form,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormInput,
} from "shards-react";

import { GetItemsContext } from "../../../context/GetItemsContext";

const NavbarSearch = ({ searchDocument }) => {
  const { inputsearch, setInputSearch, setSearch } = useContext(
    GetItemsContext
  );

  const handleChange = (evt) => {
    const { value } = evt.target;

    if (value === "") {
      setInputSearch("");
      setSearch([]);
    } else {
      setInputSearch(value);
    }
  };

  const onKeyPress = (evt) => {
    const { charCode } = evt;

    if (charCode === 13) {
      evt.preventDefault();
      searchDocument(inputsearch);
    }
  };

  return (
    <Form className="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
      <InputGroup seamless className="ml-3">
        <InputGroupAddon type="prepend">
          <InputGroupText>
            <i className="material-icons">search</i>
          </InputGroupText>
        </InputGroupAddon>
        <FormInput
          className="navbar-search"
          placeholder="Buscar documentos..."
          value={inputsearch}
          onChange={handleChange}
          onKeyPress={onKeyPress}
        />
      </InputGroup>
    </Form>
  );
};

export default NavbarSearch;
