/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:a4905aec-ae84-41a2-a9f9-90895f96a87e",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_YEPIeP0z5",
    "aws_user_pools_web_client_id": "ocpl5i0c2pml5l3itfnsl683u",
    "oauth": {
        "domain": "centrarse-app-prod.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://bibliotecacentrarse.com/books-overview/",
        "redirectSignOut": "https://bibliotecacentrarse.com/signin/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://bsrzhkg6evdxxhvu6cbpretfue.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "contentcentrarse192413-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
