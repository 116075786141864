import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Button } from "shards-react";
import { Link, Redirect } from "react-router-dom";
import Loading from "../common/Loading";
import { GetItemByIdContext } from "../../context/GetItemByIdContext";

const ViewBookForm = () => {
  const [isDocument, setIsDocument] = useState(false);
  const [encodeUrl, setEncodUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const { itemid, matchitem, file } = useContext(GetItemByIdContext);

  useEffect(() => {
    const validation = () => {
      const encodeUrl = encodeURIComponent(
        `https://${file.bucket}.s3.${file.region}.amazonaws.com/${file.key}`
      );

      const documentExtensions = /(.docx|.doc|.pdf|.xls|.xlsx|.ppt|.pptx|.csv|.txt|.jpeg|.png|.gif|.tiff|.bmp|.zip|.rar|.psd)$/i;
      const formatDocument = itemid.fileNameItem;
      const result = documentExtensions.test(formatDocument);

      setEncodUrl(encodeUrl);
      setIsDocument(result);
      setLoading(false);
    };

    validation();
  }, [isDocument, setIsDocument, setLoading, itemid, file]);

  if (matchitem === null) return <Redirect to="/books-overview" />;
  if (loading) return <Loading />;

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <Link to={`${matchitem.url}`}>
          <Button pill>&larr; Volver</Button>
        </Link>
      </Row>

      <div className="card">
        {isDocument ? (
          <iframe
            src={`https://docs.google.com/viewerng/viewer?url=${encodeUrl}&hl=es&embedded=true`}
            style={{ width: "100%", height: "100vh" }}
            height="500px"
            title={itemid.titleItem}
            frameBorder="0"
          />
        ) : (
          <iframe
            src={itemid.fileNameItem}
            style={{ width: "100%", height: "100vh" }}
            height="500px"
            title={itemid.titleItem}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          />
        )}
      </div>
    </Container>
  );
};

export default ViewBookForm;
