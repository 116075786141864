import React from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormSelect,
  FormInput,
  FormTextarea,
  Button,
} from "shards-react";
import "../../styles/EditUsersDetail.css";

const EditUserDetails = ({
  userdata,
  handleSubmit,
  handleChange,
  handleClick,
}) => {
  return (
    <div className="popup ">
      <Card small className="mb-4 popup_inner  ">
        <div className="body-scroll2 heigt-Perfil-user-form">
          <CardHeader className="border-bottom">
            <h6 className="m-0">Actualizar detalles</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit}>
                    <Row form>
                      {/* First Name */}
                      <Col md="6" className="form-group">
                        <label htmlFor="firstName">Nombre</label>
                        <FormInput
                          id="firstName"
                          name="firstName"
                          placeholder="Nombre"
                          value={userdata.firstName}
                          onChange={handleChange}
                          required
                        />
                      </Col>

                      {/* Last Name */}
                      <Col md="6" className="form-group">
                        <label htmlFor="lastName">Apellido</label>
                        <FormInput
                          id="lastName"
                          name="lastName"
                          placeholder="Apellido"
                          value={userdata.lastName}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                    </Row>

                    <Row form>
                      {/* Fecha de nacimiento */}
                      <Col md="6" className="form-group">
                        <label htmlFor="birthday">Fecha de nacimiento</label>
                        <FormInput
                          type="date"
                          id="birthday"
                          name="birthday"
                          placeholder="birthday"
                          value={userdata.birthday}
                          onChange={handleChange}
                          required
                        />
                      </Col>

                      {/* Sexo */}
                      <Col md="6" className="form-group">
                        <label htmlFor="gender">Género</label>
                        <FormSelect
                          id="gender"
                          name="gender"
                          placeholder="Género"
                          value={userdata.gender}
                          onChange={handleChange}
                          required
                        >
                          <option value=""></option>
                          <option value="Hombre">Hombre</option>
                          <option value="Mujer">Mujer</option>
                        </FormSelect>
                      </Col>
                    </Row>

                    <Row form>
                      {/* Pais */}
                      <Col md="4" className="form-group">
                        <label htmlFor="country">País</label>
                        <FormInput
                          id="country"
                          name="country"
                          placeholder="País"
                          value={userdata.country}
                          onChange={handleChange}
                          required
                        />
                      </Col>

                      {/* Direccion */}
                      <Col md="4" className="form-group">
                        <label htmlFor="address">Dirección</label>
                        <FormInput
                          id="address"
                          name="address"
                          placeholder="Dirección"
                          value={userdata.address}
                          onChange={handleChange}
                          required
                        />
                      </Col>

                      {/* Empresa */}
                      <Col md="4" className="form-group">
                        <label htmlFor="company">Empresa</label>
                        <FormInput
                          id="company"
                          name="company"
                          placeholder="Empresa"
                          value={userdata.company}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                    </Row>

                    {/* Description */}
                    <Row form>
                      <Col md="12" className="form-group">
                        <label htmlFor="description">Descripción</label>
                        <FormTextarea
                          id="description"
                          name="description"
                          placeholder="Descripción"
                          value={userdata.description}
                          onChange={handleChange}
                          required
                          rows="2"
                        />
                      </Col>
                    </Row>
                    <div className="btn-card-perfil">
                      <Button pill type="submit">
                        Actualizar
                      </Button>
                      <Button
                        pill
                        className="ml-2"
                        theme="danger"
                        onClick={handleClick}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </div>
      </Card>
    </div>
  );
};

export default EditUserDetails;
