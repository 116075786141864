import React, { useState, useEffect, useContext, Fragment } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { createSubCategory, updateSubCategory } from "../../graphql/mutations";
import { getSubCategory } from "../../graphql/queries";
import { GetCategoryContext } from "../../context/GetCategoryContext";
import AddSubCategoryForm from "./AddSubCategoryForm";
import awsExports from "../../aws-exports";
import Loading from "../common/Loading";
import Alert from "../common/Alert";

const AddSubCategory = ({ match, history }) => {
  //? Definimos un estado inical para el formulario
  const initialState = {
    imageSubCategoryLinkItem: require("../../images/content-management/13.jpeg"),
    imageSubCategoryItem: null,
    nameImageSubCategoryItem: "",
    titleSubCategoryItem: "temática",
    colorSubCategoryThemeItem: "warning",
    categoryNameItemId: "",
    descriptionSubCategoryItem:
      "However venture pursuit he am mr cordial. Forming musical am hearing studied be luckily. But in for determine what would see",
  };

  const [subcategory, setSubCategory] = useState(initialState);
  const [changeImage, setChangeImage] = useState(false);
  const [imageDelete, setImageDelete] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  //? Importamos nuestro estado global
  const { getcategory, viewsubcategory, setViewSubCategory } = useContext(
    GetCategoryContext
  );

  /*
    ? Si estamos editando una subcategoria esto nos servira
    ? para llamar sus datos y editarlas, de lo contrario no se ejecutara
  */
  useEffect(() => {
    if (match.params.id) {
      const getSubCategotyById = async (subCategoryId) => {
        try {
          const { data } = await API.graphql(
            graphqlOperation(getSubCategory, { id: subCategoryId })
          );
          const subcategory = data.getSubCategory;

          setImageDelete(subcategory.imageNameSubCategoryItem);
          setSubCategory(subcategory);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          console.log(err);
        }
      };

      getSubCategotyById(match.params.id);
    } else {
      setLoading(false);
    }
  }, [match.params.id, setSubCategory]);

  //? Definimos que tipo de accion realizaremos, si crear o actualizar
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setLoading(true);

    if (!match.params.id) {
      const subCategoryDataObject = {
        titleSubCategoryItem: subcategory.titleSubCategoryItem,
        colorSubCategoryThemeItem: subcategory.colorSubCategoryThemeItem,
        descriptionSubCategoryItem: subcategory.descriptionSubCategoryItem,
        subCategoryCategoryItemId: subcategory.categoryNameItemId,
        baseTypeSubCategoryItem: "subcategorias",
        categoryNameItemId: subcategory.categoryNameItemId,
        imageSubCategoryItem: {
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          key:
            "public/images/" +
            subcategory.nameImageSubCategoryItem.replace(/ /g, ""),
        },
        imageNameSubCategoryItem: subcategory.nameImageSubCategoryItem.replace(
          / /g,
          ""
        ),
      };

      createSubCategoryToDynamoDB(subCategoryDataObject);
    } else {
      if (changeImage) {
        const subCategoryUpdateDataObject = {
          id: match.params.id,
          titleSubCategoryItem: subcategory.titleSubCategoryItem,
          colorSubCategoryThemeItem: subcategory.colorSubCategoryThemeItem,
          descriptionSubCategoryItem: subcategory.descriptionSubCategoryItem,
          subCategoryCategoryItemId: subcategory.categoryNameItemId,
          categoryNameItemId: subcategory.categoryNameItemId,
          imageSubCategoryItem: {
            bucket: awsExports.aws_user_files_s3_bucket,
            region: awsExports.aws_user_files_s3_bucket_region,
            key:
              "public/images/" +
              subcategory.nameImageSubCategoryItem.replace(/ /g, ""),
          },
          imageNameSubCategoryItem: subcategory.nameImageSubCategoryItem.replace(
            / /g,
            ""
          ),
        };

        updateImageToS3(subCategoryUpdateDataObject);
      } else {
        const subCategoryUpdateDataObject = {
          id: match.params.id,
          titleSubCategoryItem: subcategory.titleSubCategoryItem,
          colorSubCategoryThemeItem: subcategory.colorSubCategoryThemeItem,
          descriptionSubCategoryItem: subcategory.descriptionSubCategoryItem,
          subCategoryCategoryItemId: subcategory.categoryNameItemId,
          categoryNameItemId: subcategory.categoryNameItemId,
        };

        uptadateSubCategoryData(subCategoryUpdateDataObject);
      }
    }
  };

  //? Almacenamos los la subcategoria en dynamoDB
  const createSubCategoryToDynamoDB = async (subCategoryData) => {
    try {
      await API.graphql(
        graphqlOperation(createSubCategory, { input: subCategoryData })
      );

      uploadSubCategoryImage();
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  //? Almacenamos la imagen de la subcategoria en s3
  const uploadSubCategoryImage = async () => {
    try {
      await Storage.put(
        `images/${subcategory.nameImageSubCategoryItem.replace(/ /g, "")}`,
        subcategory.imageSubCategoryItem,
        {
          contentType: `${subcategory.imageSubCategoryItem.type}`,
        }
      );

      history.push("/books-category");
      setLoading(false);
      setViewSubCategory(!viewsubcategory);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  //? Actualizamos los datos de la subcategoria en dynamoDB
  const uptadateSubCategoryData = async (subCategoryUpdateData) => {
    try {
      await API.graphql(
        graphqlOperation(updateSubCategory, { input: subCategoryUpdateData })
      );
      history.push("/books-category");
      setViewSubCategory(!viewsubcategory);
    } catch (err) {
      setLoading(false);
      setError(true);
      console.log(err);
    }
  };

  const updateImageToS3 = async (subCategoryUpdateDataObject) => {
    try {
      await Storage.remove("images/" + imageDelete);

      await Storage.put(
        `images/${subcategory.nameImageSubCategoryItem.replace(/ /g, "")}`,
        subcategory.imageSubCategoryItem,
        {
          contentType: `${subcategory.imageSubCategoryItem.type}`,
        }
      );

      console.log("image updated");
      uptadateSubCategoryData(subCategoryUpdateDataObject);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //? Actualizamos el estado del componente
  const handleChange = (e) => {
    setSubCategory({
      ...subcategory,
      [e.target.name]: e.target.value,
    });
  };

  //? Actualizamos el estado unicamente de la imagen
  const handleChangeImage = (e) => {
    const image = e.target.files[0];

    if (match.params.id) setChangeImage(true);

    setSubCategory({
      ...subcategory,
      imageSubCategoryLinkItem: URL.createObjectURL(image),
      imageSubCategoryItem: image,
      nameImageSubCategoryItem: image.name,
    });
  };

  //? Actualizamos hacia que categoria pertenecera la subcategoria
  const handleClick = (evt) => {
    const { value } = evt.target;

    setSubCategory({
      ...subcategory,
      subCategoryNameItemId: value,
    });
  };

  if (loading) return <Loading />;

  return (
    <Fragment>
      {error ? (
        <Alert
          title="Ha ocurrido un error"
          body="Revise los campos y vuelva a intentar"
        />
      ) : (
        ""
      )}

      <AddSubCategoryForm
        match={match}
        getcategory={getcategory}
        subcategory={subcategory}
        changeImage={changeImage}
        handleClick={handleClick}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleChangeImage={handleChangeImage}
      />
    </Fragment>
  );
};

export default AddSubCategory;
