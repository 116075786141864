import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";

import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";

import { API, graphqlOperation } from "aws-amplify";
import { searchItems } from "../../../graphql/queries";
import { GetItemsContext } from "../../../context/GetItemsContext";

const MainNavbar = ({ stickyTop }) => {
  const { setSearch } = useContext(GetItemsContext);

  const searchDocument = async (searchItem) => {
    searchItem.item === "" ? setSearch([]) : searchByItems(searchItem);
  };

  const searchByItems = async (searchItem) => {
    try {
      const searchResult = await API.graphql(
        graphqlOperation(searchItems, {
          filter: {
            titleItem: {
              match: searchItem,
            },
            accessItem: {
              eq: "public",
            },
          },
        })
      );

      const data = searchResult.data.searchItems.items;

      if (data.length > 0) {
        setSearch(data);
      } else {
        setSearch([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  return (
    <div className={classes}>
      <Container className="p-0">
        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0">
          <NavbarSearch searchDocument={searchDocument} />
          <NavbarNav />
          <NavbarToggle />
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
  stickyTop: true,
};

export default MainNavbar;
