// ?Layout Types
import { DefaultLayout } from "./layouts/Index";

// ?Route Views
import BooksOverview from "./views/BooksOverview";
import UserProfile from "./views/UserProfile";
import BooksCategory from "./views/BooksCategory";
import BooksApproval from "./views/BooksApproval";
import Users from "./views/Users";

// ?Route components
import AddCategory from "./components/items/AddCategory";
import AddSubCategory from "./components/items/AddSubCategory";
import AddUser from "./components/users/AddUser";
import AddItem from "./components/items/AddItem";
import ViewItem from "./components/items/ViewItem";
import ViewItemForm from "./components/items/ViewItemForm";
import ListUsers from "./components/users/ListUsers";
import AddProgramas from "./components/items/AddProgramas";
import ViewProgramItems from "./components/items/ViewProgramItems";

export default [
  // ?Component UserProfile
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: UserProfile,
  },

  // ?Components BooksOverview
  {
    path: "/books-overview",
    exact: true,
    layout: DefaultLayout,
    component: BooksOverview,
  },
  {
    path: "/books-overview/:id",
    exact: true,
    layout: DefaultLayout,
    component: ViewItem,
  },
  {
    path: "/books-overview/:id/view-book",
    exact: true,
    layout: DefaultLayout,
    component: ViewItemForm,
  },
  {
    path: "/books-overview/:id/edit",
    exact: true,
    layout: DefaultLayout,
    component: AddItem,
  },

  // ?Components BooksCategory
  {
    path: "/books-category",
    exact: true,
    layout: DefaultLayout,
    component: BooksCategory,
  },
  {
    path: "/books-category/edit-category/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddCategory,
  },
  {
    path: "/books-category/add-category",
    exact: true,
    layout: DefaultLayout,
    component: AddCategory,
  },
  {
    path: "/books-category/add-subcategory",
    exact: true,
    layout: DefaultLayout,
    component: AddSubCategory,
  },
  {
    path: "/books-category/add-subcategory/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddSubCategory,
  },
  {
    path: "/books-category/programs",
    exact: true,
    layout: DefaultLayout,
    component: ViewProgramItems,
  },
  {
    path: "/books-category/programs/add-programas",
    exact: true,
    layout: DefaultLayout,
    component: AddProgramas,
  },
  {
    path: "/books-category/programs/add-programas/:id",
    exact: true,
    layout: DefaultLayout,
    component: AddProgramas,
  },

  // ?Components BooksApproval
  {
    path: "/books-approval",
    exact: true,
    layout: DefaultLayout,
    component: BooksApproval,
  },
  {
    path: "/books-approval/:id",
    exact: true,
    layout: DefaultLayout,
    component: ViewItem,
  },
  {
    path: "/books-approval/:id/edit",
    exact: true,
    layout: DefaultLayout,
    component: AddItem,
  },
  {
    path: "/books-approval/:id/view-book",
    exact: true,
    layout: DefaultLayout,
    component: ViewItemForm,
  },
  {
    path: "/books-approval/add/book",
    exact: true,
    layout: DefaultLayout,
    component: AddItem,
  },

  // ?Components Users
  {
    path: "/users",
    exact: true,
    layout: DefaultLayout,
    component: Users,
  },
  {
    path: "/users/add-user",
    layout: DefaultLayout,
    component: AddUser,
  },
  {
    path: "/users/list-users",
    layout: DefaultLayout,
    component: ListUsers,
  },
];
