import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Badge, Button } from "shards-react";
import PageTitle from "../common/PageTitle";
import Carousel from "react-elastic-carousel";

const ViewCategory = ({
  breakPoints,
  getuserbyid,
  getcategory,
  getSubCategories,
  deleteCategoryToDB,
}) => (
  <Fragment>
    <Row noGutters className="page-header py-4">
      <Col lg="10">
        <PageTitle sm="4" title="Dimension" className="text-sm-left" />
      </Col>

      {getuserbyid.userType === "administradores" ? (
        <Col lg="2" className="bottom-center">
          <Link to="/books-category/add-category">
            <span className="btn btn-primary rounded-pill">
              Agregar Dimensión
            </span>
          </Link>
        </Col>
      ) : (
        ""
      )}
    </Row>

    <Row>
      <Carousel breakPoints={breakPoints} className="size">
        {getcategory.map((category, id) => (
          <Col lg="12" md="12" sm="12" className="mb-4 pruebaI" key={id}>
            <Card small className="card-post card-post--1 pruebaII">
              <div
                className="card-post__image card-post--1"
                style={{
                  backgroundImage: `url(https://${category.imageCategoryItem.bucket}.s3.${category.imageCategoryItem.region}.amazonaws.com/${category.imageCategoryItem.key})`,
                  cursor: "pointer",
                }}
              >
                <Badge
                  pill
                  className={`card-post__category bg-${category.colorTagCategoryItem}`}
                >
                  {category.titleCategoryItem}
                </Badge>
              </div>

              <CardBody>
                <div className="space-btn">
                  {getuserbyid.userType === "administradores" ? (
                    <Link to={`/books-category/edit-category/${category.id}`}>
                      <Button
                        className="card-botton-size"
                        outline
                        pill
                        theme="light"
                        title="Editar"
                      >
                        <span className="material-icons edit-position-bottom-card">
                          edit
                        </span>
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}

                  <Button
                    outline
                    pill
                    theme="light"
                    title="Ver"
                    className="card-botton-size"
                    onClick={() => {
                      const idCategory = category.id;

                      getSubCategories(idCategory);
                    }}
                  >
                    <span className="material-icons view-position-bottom-card">
                      visibility
                    </span>
                  </Button>

                  {getuserbyid.userType === "administradores" ? (
                    <Button
                      outline
                      pill
                      theme="light"
                      title="Eliminar"
                      className="card-botton-size"
                      onClick={() => {
                        const approveItem = { id: category.id };
                        const response = window.confirm(
                          "Deseas eleminar esta Dimensión?"
                        );

                        if (response) deleteCategoryToDB(approveItem);
                      }}
                    >
                      <span className="material-icons delete-position-bottom-card">
                        delete
                      </span>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
              <div className="text body-scroll body-scroll-container">
                <p className="scrollbox-content">
                  {category.descriptionCategoryItem}
                </p>
              </div>
            </Card>
          </Col>
        ))}
      </Carousel>
    </Row>
  </Fragment>
);

export default ViewCategory;
