import React, { useState, createContext } from "react";

export const GetCategoryContext = createContext();

export const GetCategoryProvider = (props) => {
  const [getcategory, setGetCategory] = useState();
  const [getsubcategory, setGetSubCategory] = useState();
  const [getprogram, setGetProgram] = useState();
  const [viewsubcategory, setViewSubCategory] = useState(false);
  const [refreshgetcategory, setRefreshGetCategory] = useState(true);
  const [refreshgetsubcategory, setRefreshGetSubCategory] = useState(true);
  const [refreshgetprogram, setRefreshGetProgram] = useState(true);

  return (
    <GetCategoryContext.Provider
      value={{
        getcategory,
        setGetCategory,
        getsubcategory,
        setGetSubCategory,
        getprogram,
        setGetProgram,
        viewsubcategory,
        setViewSubCategory,
        refreshgetcategory,
        setRefreshGetCategory,
        refreshgetsubcategory,
        setRefreshGetSubCategory,
        refreshgetprogram,
        setRefreshGetProgram,
      }}
    >
      {props.children}
    </GetCategoryContext.Provider>
  );
};
