import React, { useState, createContext } from "react";

export const GetUsersContext = createContext();

export const GetUsersProvider = (props) => {
  const [getusers, setGetUsers] = useState([]);
  const [refreshgetusers, setRefreshGetUsers] = useState(true);
  const [loadergetusers, setLoaderGetUsers] = useState(true);
  const [errorgetusers, setErrorGetUsers] = useState(null);
  const [contgetusers, setContGetUsers] = useState(0);
  const [contgetadminusers, setContGetAdminUsers] = useState(0);

  return (
    <GetUsersContext.Provider
      value={{
        getusers,
        setGetUsers,
        refreshgetusers,
        setRefreshGetUsers,
        loadergetusers,
        setLoaderGetUsers,
        errorgetusers,
        setErrorGetUsers,
        contgetusers,
        setContGetUsers,
        contgetadminusers,
        setContGetAdminUsers,
      }}
    >
      {props.children}
    </GetUsersContext.Provider>
  );
};
