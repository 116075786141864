import React from "react";

import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
} from "shards-react";

const UserAccountDetails = ({ userdata }) => (
  <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">Detalles de la cuenta</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              <Row form>
                {/*First Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="firstName">Nombre</label>
                  <FormInput
                    id="firstName"
                    name="firstName"
                    value={userdata.firstName}
                    placeholder="Nombre"
                    disabled="disabled"
                  />
                </Col>

                {/* Last Name */}
                <Col md="6" className="form-group">
                  <label htmlFor="lastName">Apellido</label>
                  <FormInput
                    id="lastName"
                    name="lastName"
                    value={userdata.lastName}
                    placeholder="Apellido"
                    disabled="disabled"
                  />
                </Col>
              </Row>

              <Row form>
                {/* Fecha de nacimiento */}
                <Col md="6" className="form-group">
                  <label htmlFor="birthday">Fecha de nacimiento</label>
                  <FormInput
                    id="birthday"
                    name="birthday"
                    value={userdata.birthday}
                    placeholder="Fecha de nacimiento"
                    disabled="disabled"
                  />
                </Col>

                {/* Genero */}
                <Col md="6" className="form-group">
                  <label htmlFor="gender">Género</label>
                  <FormInput
                    id="gender"
                    name="gender"
                    value={userdata.gender}
                    placeholder="Género"
                    disabled="disabled"
                  />
                </Col>
              </Row>

              {/* Email */}
              <Row form>
                <Col md="6" className="form-group">
                  <label htmlFor="email">Correo</label>
                  <FormInput
                    type="email"
                    id="email"
                    name="email"
                    value={userdata.email}
                    placeholder="Correo electrónico"
                    autoComplete="email"
                    disabled="disabled"
                  />
                </Col>

                {/* Telefono */}
                <Col md="6" className="form-group">
                  <label htmlFor="phoneNumber">Telefono</label>
                  <FormInput
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={userdata.phoneNumber}
                    placeholder="Telefono"
                    disabled="disabled"
                  />
                </Col>
              </Row>

              <Row form>
                {/* Pais */}
                <Col md="4" className="form-group">
                  <label htmlFor="country">País</label>
                  <FormInput
                    id="country"
                    name="country"
                    value={userdata.country}
                    placeholder="País"
                    disabled="disabled"
                  />
                </Col>

                {/* Direccion */}
                <Col md="4" className="form-group">
                  <label htmlFor="address">Dirección</label>
                  <FormInput
                    id="address"
                    name="address"
                    value={userdata.address}
                    placeholder="Dirección"
                    disabled="disabled"
                  />
                </Col>

                {/* Empresa */}
                <Col md="4" className="form-group">
                  <label htmlFor="company">Empresa</label>
                  <FormInput
                    id="company"
                    name="empresa"
                    value={userdata.company}
                    placeholder="Empresa"
                    disabled="disabled"
                  />
                </Col>
              </Row>

              {/* Description */}
              <Row form>
                <Col md="12" className="form-group">
                  <label htmlFor="description">Descripción</label>
                  <FormTextarea
                    id="description"
                    name="description"
                    value={userdata.description}
                    placeholder="Descripción"
                    disabled="disabled"
                    rows="2"
                  />
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

export default UserAccountDetails;
