import React from "react";
import { Container, Row, Card } from "shards-react";
import PageTitle from "../common/PageTitle";

const ListUsers = ({ listUsers }) => {
  return (
    <Container fluid className="space">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="Administradores"
          md="12"
          className="ml-sm-auto mr-sm-auto mt-4"
        />
      </Row>
      <Card>
        <div className="table-responsive-xl">
          <table className="table ">
            <thead>
              <tr>
                <th scope="col">No.</th>
                <th scope="col">Usuario</th>
                <th scope="col">Nombre</th>
                <th scope="col">Apellido</th>
                <th scope="col">Correo</th>
                <th scope="col">Telefono</th>
                <th scope="col">Dirección</th>
              </tr>
            </thead>
            <tbody>
              {listUsers.map((users, id) => (
                <tr key={id}>
                  <td>{id + 1}</td>
                  <td>{users.username}</td>
                  <td>{users.firstName}</td>
                  <td>{users.lastName}</td>
                  <td>{users.email}</td>
                  <td>{users.phoneNumber}</td>
                  <td>{users.address}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </Container>
  );
};

export default ListUsers;
