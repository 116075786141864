import React, { useState, useContext } from "react";
import { Link, Redirect } from "react-router-dom";
import { Row, Container, Button } from "shards-react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { deleteProgram } from "../../graphql/mutations";
import { getProgram } from "../../graphql/queries";

import { GetUserByIdContext } from "../../context/GetUserByIdContext";
import { GetCategoryContext } from "../../context/GetCategoryContext";

import ViewPrograms from "./ViewPrograms";
import Loading from "../common/Loading";
import ItemByProgram from "./ItemByProgram";
import MenssageCarrusell from "../common/MenssageCarrusell";

const ViewProgramItems = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState(null);

  //? Estados para visualizar los items por programas
  const [vieitems, setViewItems] = useState(false);
  const [listitems, setListItems] = useState(null);

  const { getuserbyid } = useContext(GetUserByIdContext);

  const {
    getcategory,
    getprogram,
    refreshgetcategory,
    refreshgetprogram,
  } = useContext(GetCategoryContext);

  const breakPoints = [
    { width: 1, itemsToShow: 1, outline: " none" },
    { width: 550, itemsToShow: 2, outline: " none" },
    { width: 768, itemsToShow: 3, outline: " none" },
    { width: 1200, itemsToShow: 4, outline: " none" },
  ];

  //? Eliminamos el programa en dynamoDB
  const deleteProgramToDB = async (idProgram) => {
    setLoading(true);

    const dataProgram = getprogram.find(
      (program) => program.id === idProgram.id
    );

    console.log(dataProgram);
    try {
      await API.graphql(graphqlOperation(deleteProgram, { input: idProgram }));

      console.log("delete to db completed success");
      deleteProgramToS3(dataProgram.imageNameProgramItem);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //? Eliminamos la imagen del programa en s3
  const deleteProgramToS3 = async (imageNameProgramItem) => {
    try {
      await Storage.remove("images/" + imageNameProgramItem);
      history.push("/books-category");
      console.log("delete to s3 completed success");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  //? Haremos la peticion de todas los items de ese programa
  const getItemsByProgram = async (idProgram) => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(getProgram, { id: idProgram })
      );

      const items = data.getProgram.programItems.items;
      const privateItems = items.filter(
        (item) => item.accessItem !== "private"
      );

      const categoryItem = data.getProgram.categoryProgramItem;

      setCategory(categoryItem);
      setListItems(privateItems);
      setViewItems(true);
    } catch (err) {
      console.log(err);
    }
  };

  if (refreshgetcategory) return <Redirect to="/books-category" />;
  if (loading) return <Loading />;

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <Link to="/books-category">
          <Button pill>&larr; Volver</Button>
        </Link>
      </Row>
      {refreshgetprogram ? (
        <Loading />
      ) : getprogram.length === 0 ? (
        <MenssageCarrusell />
      ) : (
        <ViewPrograms
          getuserbyid={getuserbyid}
          getcategory={getcategory}
          getprogram={getprogram}
          breakPoints={breakPoints}
          deleteProgramToDB={deleteProgramToDB}
          getItemsByProgram={getItemsByProgram}
        />
      )}
      {vieitems ? (
        <ItemByProgram getitems={listitems} categoryItem={category} />
      ) : (
        ""
      )}
    </Container>
  );
};

export default ViewProgramItems;
