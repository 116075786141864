import React from "react";
import PageTitle from "../common/PageTitle";
import { Link } from "react-router-dom";

import {
  Button,
  Container,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Form,
  FormInput,
  FormGroup,
  FormFeedback,
  Row,
} from "shards-react";

const AddUserForm = ({ userData, handleChange, handleSubmit }) => {
  if (userData.verified)
    return (
      <Container fluid className="main-content-container">
        <Row className="mt-5">
          <Col sm={{ size: 5, order: 3, offset: 3 }} className="mt-5">
            <div className="text-center">
              <strong className="text-muted d-block mb-2">Confirmación</strong>
            </div>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <InputGroup className="mb-3">
                  <InputGroupAddon type="prepend">
                    <InputGroupText>@</InputGroupText>
                  </InputGroupAddon>
                  <FormInput placeholder="Usuario" value={userData.username} />
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <FormInput
                  type="text"
                  placeholder="Código de verificación"
                  name="confirmationCode"
                  value={userData.confirmationCode}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <div className="text-center">
                <Button type="submit" theme="primary" pill>
                  Aceptar
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    );

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <Link to="/users">
          <Button pill>&larr; Volver</Button>
        </Link>
        <PageTitle
          title="Agregar Administradores"
          md="12"
          className="ml-sm-auto mr-sm-auto mt-4"
        />
      </Row>
      <Row>
        <Col log="4">
          <Card small className="mb-4 pt-3">
            <CardHeader className="border-bottom text-center">
              <div className="mb-3 mx-auto">
                <img
                  className="rounded-circle"
                  src={require("../../images/avatars/4.jpeg")}
                  alt="userData.firstName"
                  width="110"
                />
              </div>
              <h4 className="mb-0">
                {userData.firstName + " " + userData.lastName}
              </h4>
              <span className="text-muted d-block mb-2">{userData.email}</span>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-4">
                <strong className="text-muted d-block mb-2">Descripción</strong>
                <span>{userData.description}</span>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
        <Col lg="8">
          <Card small>
            <CardHeader className="border-bottom">
              <h6 className="m-0">Datos generales del usuario</h6>
            </CardHeader>
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form onSubmit={handleSubmit}>
                      <Row form>
                        <Col md="12" className="form-group">
                          <label htmlFor="address">Usuario</label>
                          <FormInput
                            rows="2"
                            id="username"
                            name="username"
                            placeholder="Usuario"
                            onChange={handleChange}
                            value={userData.username}
                            required
                          />
                        </Col>

                        <Col md="6" className="form-group">
                          <label htmlFor="firstName">Nombre</label>
                          <FormInput
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="Nombre"
                            onChange={handleChange}
                            value={userData.firstName}
                            required
                          />
                        </Col>

                        <Col md="6" className="form-group">
                          <label htmlFor="lastName">Apellido</label>
                          <FormInput
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Apellido"
                            onChange={handleChange}
                            value={userData.lastName}
                            required
                          />
                        </Col>

                        <Col md="6" className="form-group">
                          <label htmlFor="email">Correo electrónico</label>
                          <FormInput
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Correo electrónico"
                            onChange={handleChange}
                            value={userData.email}
                          />
                        </Col>
                        <Col md="6" className="form-group">
                          <label htmlFor="password">Contraseña</label>
                          <FormInput
                            type="password"
                            id="password"
                            name="password"
                            placeholder="Contraseña"
                            onChange={handleChange}
                            value={userData.password}
                            required
                            valid={userData.password.length >= 6 ? true : false}
                            invalid={
                              userData.password.length === 0 ||
                              userData.password.length >= 6
                                ? false
                                : true
                            }
                          />
                          <FormFeedback valid />
                          <FormFeedback invalid>
                            Debe tener tener al menos 6 caracteres
                          </FormFeedback>
                        </Col>

                        <Col md="6" className="form-group">
                          <label htmlFor="phone_number">Teléfono</label>
                          <FormInput
                            rows="2"
                            id="phone_number"
                            name="phone_number"
                            placeholder="Teléfono"
                            type="number"
                            onChange={handleChange}
                            value={userData.phone_number}
                            required
                            valid={
                              userData.phone_number.length >= 6 ? true : false
                            }
                            invalid={
                              userData.phone_number.length === 0 ||
                              userData.phone_number.length >= 8
                                ? false
                                : true
                            }
                          />
                          <FormFeedback valid />
                          <FormFeedback invalid>
                            Debe colocar un numero de telefono válido
                          </FormFeedback>
                        </Col>
                      </Row>
                      <Row>
                        <CardBody>
                          <div className="text-center">
                            <Button theme="primary" type="submit" pill>
                              Guardar
                            </Button>
                            <Link to="/users">
                              <Button theme="danger" pill className="ml-3">
                                Cancelar
                              </Button>
                            </Link>
                          </div>
                        </CardBody>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddUserForm;
