import React from "react";
import { Link } from "react-router-dom";

import {
  Row,
  Button,
  Badge,
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  Form,
  FormSelect,
  FormInput,
  FormTextarea,
} from "shards-react";
import PageTitle from "../common/PageTitle";

const AddSubCategoryForm = ({
  match,
  getcategory,
  changeImage,
  subcategory,
  handleClick,
  handleChange,
  handleSubmit,
  handleChangeImage,
}) => (
  <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <Link to="/books-category">
        <Button pill>&larr; Volver</Button>
      </Link>
      <PageTitle
        title="Agregar / Editar Tematica"
        md="12"
        className="ml-sm-auto mr-sm-auto mt-4"
      />
    </Row>

    <Row>
      <Col lg="4">
        <Card small className="card-post card-post--1 mb-4 ">
          {!match.params.id ? (
            <div
              className="card-post__image card-post--1"
              style={{
                backgroundImage: `url(${subcategory.imageSubCategoryLinkItem})`,
              }}
            >
              <Badge
                pill
                className={`card-post__category bg-${subcategory.colorSubCategoryThemeItem}`}
              >
                {subcategory.titleSubCategoryItem}
              </Badge>
            </div>
          ) : !changeImage ? (
            <div
              className="card-post__image card-post--1"
              style={{
                backgroundImage: `url(https://${subcategory.imageSubCategoryItem.bucket}.s3.${subcategory.imageSubCategoryItem.region}.amazonaws.com/${subcategory.imageSubCategoryItem.key})`,
              }}
            >
              <Badge
                pill
                className={`card-post__category bg-${subcategory.colorSubCategoryThemeItem}`}
              >
                {subcategory.titleSubCategoryItem}
              </Badge>
            </div>
          ) : (
            <div
              className="card-post__image card-post--1"
              style={{
                backgroundImage: `url(${subcategory.imageSubCategoryLinkItem})`,
              }}
            >
              <Badge
                pill
                className={`card-post__category bg-${subcategory.colorSubCategoryThemeItem}`}
              >
                {subcategory.titleSubCategoryItem}
              </Badge>
            </div>
          )}

          <CardBody>
            <h5 className="card-title">
              <span className="text-fiord-blue">
                {subcategory.titleSubCategoryItem}
              </span>
            </h5>
            <p className="card-text d-inline-block mb-3">
              {subcategory.descriptionSubCategoryItem}
            </p>
          </CardBody>
        </Card>
      </Col>

      <Col lg="8">
        <Card small>
          <CardHeader className="border-bottom">
            <h6 className="m-0">Agregar / Editar temática</h6>
          </CardHeader>
          <ListGroup flush>
            <ListGroupItem className="p-3">
              <Row>
                <Col>
                  <Form onSubmit={handleSubmit}>
                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="titleCategoryItem">
                          Nombre Temáticas
                        </label>
                        <FormInput
                          id="titleSubCategoryItem"
                          name="titleSubCategoryItem"
                          value={subcategory.titleSubCategoryItem}
                          onChange={handleChange}
                          required
                        />
                      </Col>
                      <Col md="6" className="form-group">
                        <label>Color Etiqueta</label>
                        <InputGroup className="mb-3">
                          <FormSelect
                            name="colorSubCategoryThemeItem"
                            value={subcategory.colorSubCategoryThemeItem}
                            onChange={handleChange}
                            required
                          >
                            <option value="primary">Azul</option>
                            <option value="secondary">Gris</option>
                            <option value="success">Verde</option>
                            <option value="info">Celeste</option>
                            <option value="warning">Amarillo</option>
                            <option value="danger">Purpura</option>
                            <option value="dark">Negro</option>
                          </FormSelect>
                        </InputGroup>
                      </Col>
                      <Col md="12" className="form-group">
                        <label htmlFor="descriptionSubCategoryItem">
                          Descripción
                        </label>
                        <FormTextarea
                          id="descriptionSubCategoryItem"
                          name="descriptionSubCategoryItem"
                          value={subcategory.descriptionSubCategoryItem}
                          onChange={handleChange}
                          rows="2"
                          required
                        />
                      </Col>

                      <Col md="6" className="form-group">
                        <InputGroup className="mb-3">
                          <InputGroupAddon type="prepend">
                            <InputGroupText>Dimensiones</InputGroupText>
                          </InputGroupAddon>
                          <FormSelect
                            name="categoryNameItemId"
                            onChange={handleChange}
                            onClick={handleClick}
                            value={subcategory.categoryNameItemId}
                            required
                          >
                            <option value=""></option>
                            {getcategory.map((category, id) => (
                              <option key={id} value={category.id}>
                                {category.titleCategoryItem}
                              </option>
                            ))}
                          </FormSelect>
                        </InputGroup>
                      </Col>

                      {!match.params.id ? (
                        <Col md="6" className="form-group">
                          <div className="custom-file mb-3">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="imageSubCategoryItem"
                              name="imageSubCategoryItem"
                              onChange={handleChangeImage}
                              required
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="imageSubCategoryItem"
                            >
                              {subcategory.imageSubCategoryItem === null
                                ? "Elegir portada"
                                : subcategory.nameImageSubCategoryItem}
                            </label>
                          </div>
                        </Col>
                      ) : (
                        <Col md="6" className="form-group">
                          <div className="custom-file mb-3">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="imageSubCategoryItem"
                              name="imageSubCategoryItem"
                              onChange={handleChangeImage}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="imageSubCategoryItem"
                            >
                              {!changeImage
                                ? "Cambiar portada"
                                : subcategory.nameImageSubCategoryItem}
                            </label>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <CardBody>
                        <div className="text-center">
                          <Button pill type="submit" theme="primary">
                            Guardar
                          </Button>
                          <Link to="/books-category">
                            <Button pill theme="danger" className="ml-3">
                              Cancelar
                            </Button>
                          </Link>
                        </div>
                      </CardBody>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </Card>
      </Col>
    </Row>
  </Container>
);

export default AddSubCategoryForm;
