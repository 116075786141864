import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";

import { ShowPass } from "./common/ShowPass";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  Redes: {
    marginTop: theme.spacing(3),
    borderRadius: "30px",
    height: "75%",
  },

  form: {
    width: "100%",
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: "30px",
    height: "64%",
  },

  divisor: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },

  divisor2: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },

  chip: {
    marginTop: theme.spacing(3),
    position: "relative",
    borderColor: "black",
    borderRadius: "30px",
    height: "75%",
  },

  pasword: {
    position: "relative",
    marginTop: "4%",
    textAlign: "center",
  },

  linkRecoverPassword: {
    position: "relative",
    marginTop: "4%",
    textAlign: "end",
  },

  linkCreateAccount: {
    position: "relative",
    marginTop: "4%",
    textAlign: "center",
  },

  divider: {
    position: "relative",
    marginTop: "5%",
    textAlign: "center",
  },

  space: {
    position: "relative",
  },

  in: {
    marginTop: "4%",
  },
}));

const SignInForm = ({ form, onSubmit, onChange, onClick, onMouseDown }) => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <div style={{ width: "40%", height: "40%" }}>
          <img
            className="card-img-top"
            alt=""
            src={require("../images/Logo-biblioteca.png")}
          />
        </div>
        <Typography component="h1" variant="h6" className={classes.in}>
          Iniciar Sesión
        </Typography>

        <Grid container>
          <Grid item xs={5}>
            <Divider className={classes.divisor} />
          </Grid>
          <Grid item xs={2}>
            <p className={classes.divisor2}>o</p>
          </Grid>
          <Grid item xs={5}>
            <Divider className={classes.divisor} />
          </Grid>
        </Grid>

        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Usuario"
                name="username"
                autoComplete="fname"
                autoFocus
                value={form.username}
                onChange={onChange}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={clsx(classes.margin, classes.textField)}
              >
                <ShowPass
                  show={form}
                  onChangeShow={onChange}
                  onClickShow={onClick}
                  onMouseDown={onMouseDown}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className={classes.space}>
            <Grid item xs className={classes.linkRecoverPassword}>
              <Link to="/password-reset" variant="body1">
                ¿Has olvidado tu contraseña?
              </Link>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Button
                style={{ outline: "none" }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Ingresar
              </Button>
            </Grid>
          </Grid>

          <Grid container className={classes.linkCreateAccount}>
            <Grid item xs={12}>
              ¿No tienes una cuenta?
              <Link to="/signup" variant="body1">
                {" "}
                Registrate
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default SignInForm;
