export default function () {
  return [
    {
      title: "Perfil",
      htmlBefore: '<i class="material-icons">account_circle</i>',
      to: "/user-profile",
    },
    {
      title: "Biblioteca",
      htmlBefore: '<i class="material-icons">file_copy</i>',
      to: "/books-overview",
    },
    {
      title: "Dimensiones",
      htmlBefore: '<i class="material-icons">create_new_folder</i>',
      to: "/books-category",
    },
    {
      title: "Admin. de documentos",
      htmlBefore: '<i class="material-icons">cloud_upload</i>',
      to: "/books-approval",
    },
    {
      title: "Admin. de usuarios",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/users",
    },
  ];
}
