import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

import { GetItemsContext } from "../context/GetItemsContext";
import { GetItemByIdContext } from "../context/GetItemByIdContext";
import { GetUserByIdContext } from "../context/GetUserByIdContext";
import { listItems } from "../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";

import PageTitle from "../components/common/PageTitle";
import Loading from "../components/common/Loading";
import Alert from "../components/common/Alert";
import Pagination from "../components/Pagination";

import { Container, Row, Col, Card, CardFooter, Badge } from "shards-react";
import "../styles/Scroll.css";
import "../styles/CssViewsBooks.css";

const BooksOverview = () => {
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(12);
  const [extension] = useState(
    /(.docx|.doc|.pdf|.xls|.xlsx|.ppt|.pptx|.csv|.txt|.jpeg|.png|.gif|.tiff|.bmp|.zip|.rar|.psd)$/i
  );
  const { setMatchItem } = useContext(GetItemByIdContext);
  const { refreshgetuserbyid } = useContext(GetUserByIdContext);

  const {
    getitems,
    refreshgetitems,
    setGetItems,
    setRefreshGetItems,
    search,
  } = useContext(GetItemsContext);

  //? Listamos todos los items disponibles
  useEffect(() => {
    if (refreshgetitems) {
      const getItemsResource = async () => {
        try {
          const { data } = await API.graphql(
            graphqlOperation(listItems, {
              filter: {
                accessItem: {
                  eq: "public",
                },
              },
            })
          );
          const { items } = data.listItems;

          setGetItems(items);
          setRefreshGetItems(!refreshgetitems);
        } catch (err) {
          setRefreshGetItems(!refreshgetitems);
          setError(true);
          console.log(err);
        }
      };

      getItemsResource();
    }

    setMatchItem(null);
  }, [setGetItems, refreshgetitems, setRefreshGetItems, setMatchItem]);

  const ShowItems = ({ dataItem }) => {
    return (
      <Fragment>
        {error ? (
          <Alert title="An unexpected error has occurred" body="" />
        ) : (
          ""
        )}

        <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Biblioteca" className="text-sm-left" />
          </Row>

          <Row>
            {dataItem.map((item, id) => (
              <Col
                xs="12"
                lg="4"
                xl="3"
                md="6"
                sm="12"
                className="mb-4"
                key={id}
              >
                <Card small className="card-post card-post--1">
                  <Link to={`/books-overview/${item.id}`}>
                    {extension.test(item.fileNameItem) ? (
                      <div
                        className="card-post__image"
                        style={{
                          backgroundImage: `url(${require("../images/content-management/icons8-documents-96.png")})`,
                        }}
                      >
                        <Badge
                          pill
                          className={`card-post__category bg-${item.categoryItem.colorTagCategoryItem}`}
                        >
                          {item.categoryItem.titleCategoryItem}
                        </Badge>
                      </div>
                    ) : (
                      <div
                        className="card-post__image"
                        style={{
                          backgroundImage: `url(${require("../images/content-management/icons8-video.png")})`,
                        }}
                      >
                        <Badge
                          pill
                          className={`card-post__category bg-${item.categoryItem.colorTagCategoryItem}`}
                        >
                          {item.categoryItem.titleCategoryItem}
                        </Badge>
                      </div>
                    )}
                  </Link>

                  <div className="item-card-title">
                    {item.titleItem.length > 45
                      ? item.titleItem.substring(0, 45) + "..."
                      : item.titleItem}
                  </div>

                  <div className="text body-scroll">
                    <p className="scrollbox-content">{item.descriptionItem}</p>
                  </div>

                  <CardFooter
                    className="text-muted border-top py-3"
                    style={{ height: "105px" }}
                  >
                    <span className="d-inline-block">
                      <span className="text-fiord-blue">
                        {item.authorItem.length > 43
                          ? "Por " + item.authorItem.substring(0, 43) + "..."
                          : "Por " + item.authorItem}
                      </span>
                      <span className="text-fiord-blue">
                        {` en ${item.categoryItem.titleCategoryItem}`}
                      </span>
                    </span>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={getitems.length}
            paginate={paginate}
          />
        </Container>
      </Fragment>
    );
  };

  //? Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = getitems.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (refreshgetitems) return <Loading />;
  if (refreshgetuserbyid) return <Loading />;

  if (search.length > 0) return <ShowItems dataItem={search} />;

  return <ShowItems dataItem={currentPosts} />;
};

export default BooksOverview;
