import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Row, Col, Card, CardFooter, Badge } from "shards-react";

const ItemByProgram = ({ getitems, categoryItem }) => {
  const [extension] = useState(
    /(.docx|.doc|.pdf|.xls|.xlsx|.ppt|.pptx|.csv|.txt|.jpeg|.png|.gif|.tiff|.bmp|.zip|.rar|.psd)$/i
  );

  return (
    <Row>
      {getitems.map((item, id) => (
        <Col xs="12" lg="4" xl="3" md="6" sm="12" className="mb-4" key={id}>
          <Card small className="card-post card-post--1">
            <Link to={`/books-overview/${item.id}`}>
              {extension.test(item.fileNameItem) ? (
                <div
                  className="card-post__image"
                  style={{
                    backgroundImage: `url(${require("../../images/content-management/icons8-documents-96.png")})`,
                  }}
                >
                  <Badge
                    pill
                    className={`card-post__category bg-${categoryItem.colorTagCategoryItem}`}
                  >
                    {categoryItem.titleCategoryItem}
                  </Badge>
                </div>
              ) : (
                <div
                  className="card-post__image"
                  style={{
                    backgroundImage: `url(${require("../../images/content-management/icons8-video.png")})`,
                  }}
                >
                  <Badge
                    pill
                    className={`card-post__category bg-${categoryItem.colorTagCategoryItem}`}
                  >
                    {categoryItem.titleCategoryItem}
                  </Badge>
                </div>
              )}
            </Link>

            <div className="item-card-title">
              {item.titleItem.length > 47
                ? item.titleItem.substring(0, 47) + "..."
                : item.titleItem}
            </div>

            <div className="text body-scroll">
              <p className="scrollbox-content">{item.descriptionItem}</p>
            </div>

            <CardFooter
              className="text-muted border-top py-3"
              style={{ height: "105px" }}
            >
              <span className="d-inline-block">
                <span className="text-fiord-blue">
                  {item.authorItem.length > 43
                    ? "Por " + item.authorItem.substring(0, 43) + "..."
                    : "Por " + item.authorItem}
                </span>
                <span className="text-fiord-blue">
                  {` en ${categoryItem.titleCategoryItem}`}
                </span>
              </span>
            </CardFooter>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ItemByProgram;
