import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Badge, Button } from "shards-react";
import Carousel from "react-elastic-carousel";
import PageTitle from "../common/PageTitle";

const ViewPrograms = ({
  breakPoints,
  getuserbyid,
  getprogram,
  deleteProgramToDB,
  getItemsByProgram,
}) => (
  <div className=" slide-tematica effectTematica">
    <Row noGutters className="page-header">
      <Col lg="10">
        <PageTitle sm="4" title="Programas" className="text-sm-left" />
      </Col>

      {getuserbyid.userType === "administradores" ? (
        <Col lg="2" className="bottom-center">
          <Link to="/books-category/programs/add-programas">
            <span className="btn btn-primary rounded-pill">
              Agregar Programa
            </span>
          </Link>
        </Col>
      ) : (
        ""
      )}
    </Row>

    <Row>
      <Carousel
        breakPoints={breakPoints}
        className="size slide-bottom separator effect"
      >
        {getprogram.map((program, id) => (
          <Col lg="12" md="12" sm="12" className="mb-4 pruebaI" key={id}>
            <Card small className="card-post card-post--1 pruebaII">
              <div
                className="card-post__image card-post--1"
                style={{
                  backgroundImage: `url(https://${program.imageProgramItem.bucket}.s3.${program.imageProgramItem.region}.amazonaws.com/${program.imageProgramItem.key})`,
                  cursor: "pointer",
                }}
              >
                <Badge
                  pill
                  className={`card-post__category bg-${program.colorTagProgramItem}`}
                >
                  {program.titleProgramItem}
                </Badge>
              </div>

              <CardBody>
                <div className="space-btn">
                  {getuserbyid.userType === "administradores" ? (
                    <Link
                      to={`/books-category/programs/add-programas/${program.id}`}
                    >
                      <Button
                        className="card-botton-size "
                        outline
                        pill
                        theme="light"
                        title="Editar"
                      >
                        <span className="material-icons edit-position-bottom-card">
                          edit
                        </span>
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}

                  <Button
                    outline
                    pill
                    theme="light"
                    title="Ver"
                    className="card-botton-size"
                    onClick={() => {
                      const idProgram = program.id;
                      getItemsByProgram(idProgram);
                    }}
                  >
                    <span className="material-icons view-position-bottom-card">
                      visibility
                    </span>
                  </Button>

                  {getuserbyid.userType === "administradores" ? (
                    <Button
                      outline
                      pill
                      theme="light"
                      title="Eliminar"
                      className="card-botton-size"
                      onClick={() => {
                        const idProgram = { id: program.id };
                        const response = window.confirm(
                          "Deseas eleminar este Programa?"
                        );

                        if (response) deleteProgramToDB(idProgram);
                      }}
                    >
                      <span className="material-icons delete-position-bottom-card">
                        delete
                      </span>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
              <div className="text body-scroll body-scroll-container">
                <p className="scrollbox-content">
                  {program.descriptionProgramItem}
                </p>
              </div>
            </Card>
          </Col>
        ))}
      </Carousel>
    </Row>
  </div>
);

export default ViewPrograms;
